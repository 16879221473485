.nav-tabs {

  &.nav-tabs-underline {
    border: 0;
    margin-bottom: 3px;
  }

  .nav-link {
    border: 0;
    border-bottom: solid 3px transparent;
    border-radius: 0;
    color: $text-color;

    @include hover-focus {
      border-color: transparent;
    }

    &.disabled {
      color: $nav-link-disabled-color;
      background-color: transparent;
      border-color: transparent;
      opacity: .6 !important;
      @include hover-focus {
        cursor: default !important;
      }
    }
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    color: $text-color;
    background-color: $white;
    border-color: $primary;
  }

  &.nav-tabs-primary {
    .nav-link {
      background-color: $primary;
      color: $white;
      opacity: .8;

      @include hover-focus {
        opacity: 1;
      }

    }
    .nav-link.active,
    .nav-item.show .nav-link {
      opacity: 1;
      border-color: $white;
    }
  }

}
