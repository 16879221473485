
#snackbar-parent-container {
  margin-inline-start: 0.5rem;
}
#snackbar-parent-container ::v-deep .v-snack__wrapper {
  width: initial;
  background-color: var(--t-color-navigation-surface-inverted);
}
p {
  display: block;
  font-weight: var(--t-font-weight-6);
  color: var(--t-color-text-inverted);
  margin: 0;
}
#snackbar-parent-container ::v-deep .v-snack__content {
  display: initial;
}
.container-snackbar {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
}
.container-icon-progress {
  display: flex;
  flex-shrink: 0;
}
.container-content {
  flex: 1;
  margin-block-start: .125rem;
}
.container-secondary-actions {
  display: flex;
  margin-inline-start: -3rem;
}
.v-icon {
  color: var(--t-color-text-inverted);
  border-radius: var(--t-radius-round);
  padding: .25rem;
}
.icon-green {
  background-color: var(--t-color-status-success);
}
.icon-red {
  background-color: var(--t-color-status-danger);
}
.icon-yellow {
  background-color: var(--t-color-status-warning);
}
.container-dismiss-button {
  margin-block-start: -.125rem;
}
.v-snack {
  width: 300px !important;
  max-width: 500px !important;
  z-index: 11000 !important;
}
#app a.snack-link {
  color: var(--t-color-text-link-blue) !important;
  text-decoration: none;
}
