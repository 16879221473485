
@import "~@/assets/scss/variables";

  #add-card-nickname {
    font-size: .875rem;
    font-weight: 500;
    color: var(--t-color-text-link);

    &:hover {
      text-decoration: underline;
    }
  }

  .disabled {
    background-color: $gray-100;
  }
