
@import "~@/assets/scss/variables";

#toaster {
  width: 80vw;
  margin: 2em 10vw 2em 10vw;
  top: 60px;
  left: 0;
  position: fixed;
  z-index: $zindex-toast;
  background-color: var(--t-color-surface);

  &.top-right-sm {
    width: 400px;
    margin: 0;
    top: 10px;
    right: 10px;
    left: initial;
    position: fixed;
    z-index: 9004;
    background-color: var(--t-color-surface);

    #toast {
      padding: 30px;

      &.alert-black {
        background: var(--t-color-surface-inverted);
        color: var(--t-color-text-inverted);
        box-shadow: 0px 4px 8px 1px rgb(0 0 0 / 30%);

        &:hover {
          span {
            color: var(--t-color-text-inverted);
          }
        }
      }
    }
  }

  &.sidebar-visible {
    /* If we need specific styling when the sidebar is open it can go here. */
  }

  #toast {
    margin: 0 auto;
  }
}
