// For importing into .VUE files, so you won't need 2 import statements
// https://vuetifyjs.com/en/customization/sass-variables/#example-variable-file

@import '~@tangocard/swag/src/scss/vuetify/variables';
@import "theme";
@import "../../../node_modules/bootstrap/scss/variables";
@import "~@tangocard/swag/src/scss/partials/common/mango_colors";

// Globals
$font-size-root: 0.875rem;
// $border-radius-root: 0.5rem;

// Buttons
$btn-sizes: (
  'default': 2.25rem,
);
$btn-focus-opacity: (
  'default': 1,
);


// Place Order grid breakpoints that still need to be cleaned up in RG-3314
$breakpoint-547: 547px; // 2 usages
$breakpoint-980: 980px; // 3 usages
$breakpoint-1260: 1260px; // 2 usages

// Breakpoints - sm, md, lg, xl match bootstrap vars
// when using @media queries, use these breakpoints as the width >= to the
// screen width you're targeting so every page is consistent, like so:
// (min-width: $breakpoint-md)
// or
// (max-width: $breakpoint-md - 1)
$breakpoint-xs: 406px;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1350px;

$body-font-family: "Gotham-Book", "Helvetica Neue", Helvetica, Arial, sans-serif;
$text-field-border-radius: 0;

$vuetify-sm: 960px;
$vuetify-lg: 1440px;
