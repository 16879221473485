
@import "~@/assets/scss/variables";

.search {
  background-color: var(--t-color-surface);
  border: solid var(--t-border-size-1) var(--t-color-border);
  align-items: center;
  justify-content: center;
  font-size: 24px;

  &.extra-margin {
    margin-bottom: 24px;
  }

  .delete-icon {
    cursor: pointer;
  }

  .search-bar-input{
    font-size: 14px;
    border: 0;
  }
}

.search.disabled, .disabled > .search-bar-input {
  background-color: $input-disabled-bg;
  cursor: not-allowed;
}

.form-control {
  height: 36px;
}
