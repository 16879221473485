
@import "~@/assets/scss/global.scss";
@import "~@/assets/scss/variables";

.banner-wrapper{
  position: relative;
  display: block;
  flex: 1;
}

.row.banner-content{
  min-height: 40px;
  margin: 5px 5px !important;
  font-size: 16px;
  font-weight: 400;
  &.icon-top{
    align-items: flex-start;
  }
  p{
    margin-top: 0;
    &:last-child{
      margin-bottom: 0;
    }
  }
}

.sticky-alert{
  background-color:#fff;
  position: fixed;
  top:0;
  width: calc(100% - 72px);
  left:62px;
  z-index: 9001;
  transition: left 300ms, width 300ms ease;
  margin:auto;

  &.alert-sidebar-open {
    width: calc(100% - 220px);
    left: 220px;
    @include media-breakpoint-up(md) {
      width: calc(100% - 233px);
    }
  }

  .info-icon {
    color:var(--t-color-text);
    margin-right:12px;
  }

  .link-button{
    font-weight: 500;
    color: var(--t-color-text-link);
    text-decoration: underline;
    margin-left:12px;
  }
}
