// Used for styling Google Maps API
@import "../mixins";
@import "../../../../node_modules/bootstrap/scss/variables";

.pac-container {
    z-index: $zindex-modal + 1;

  .pac-matched {
    @include gotham-medium();
    z-index: $zindex-modal + 1;
    font-size: 0.9rem;
    line-height: 1.5;
  }
  .pac-item-query {
    @include gotham-book();
    z-index: $zindex-modal + 1;
    font-size: 0.9rem;
    line-height: 1.5;
  }
  .pac-item {
    @include gotham-book();
    z-index: $zindex-modal + 1;
    font-size: 0.9rem;
    line-height: 1.5;
    .pac-icon {
      opacity: 0;
    }
  }
}
