.modal-header {
  border-bottom: 1px solid $info-light !important;
  word-break: break-all;
}

.standard-modal-header {
  padding-top: 32px;
  display: block;
  border-bottom: 0 !important;
}

.standard-modal-footer {
  justify-content: center;
  .btn {
    min-width: 200px;
  }
}


.modal-full-height {
  .modal {
    overflow-y: hidden;
  }
  .modal-dialog {
    margin-top: 0;
    margin-bottom: 0;
    height: 100%;

    @include media-breakpoint-up(sm) {
        max-width: 100%;
    }

  }
  .modal-content, .modal-body {
    height: 100%;
  }
  .modal-body {
    overflow-y: auto;
  }
}

.modal-wide {
  @include media-breakpoint-up(lg) {
    .modal-lg { max-width: 1100px; }
  }
}
