
@import "~@/assets/scss/mixins";
@import "~@/assets/scss/variables";

#sidebar {
  .sidebar-menu {
    position: fixed;
    z-index: $zindex-iconav;
    width: $iconav-width-closed;
    transition: width 0.2s ease-in-out, left 0.2s ease-in-out;
    top: 0;
    left: 0;
    right: auto;
    bottom: 0;
    overflow: hidden;
    ::v-deep {
      // the sidebar padding needs 1 extra px here to account for the border on the right
      .v-list--nav {
        padding-right: 9px;
      }

      .v-list-item.v-list-item--link {
        padding: 0 11px;

        &.active {
          background-color: #e9e8e8;
          border-radius: 12px;
        }

        &::before {
          border-radius: 12px;
        }

        .v-ripple__container {
          border-radius: 12px;
        }

        .icon-badge {
          position: absolute;
          z-index: 1;
          left: 26px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #E51522;
          border: var(--t-border-size-1) solid var(--t-color-surface-raised);
        }

        &.active .icon-badge,
        &:hover .icon-badge {
          border: var(--t-border-size-1) solid #e9e8e8;
        }

        .v-icon {
          color: $iconav-brand-color !important;
        }

        .v-list-item__title {
          color: $iconav-brand-color !important;
          font-size: 14px;
        }

        .v-list-item__icon:first-child {
          margin-right: 14px;
        }
      }
    }
  }

  &.controls-open {
    .sidebar-menu {
      width: $iconav-width-open;
      left: 0;
    }

    &::before {
      content: "";
      backdrop-filter: blur(4px);
      transition: 250ms;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: $zindex-iconav;

      @include media-breakpoint-up(md) {
        display: none;
        overflow: hidden;

        &.emulating {
          top: 40px !important;
        }
      }

      &.controls-open {
        .sidebar-menu {
          width: $iconav-width-open;
          left: 0;
        }

        &::before {
          content: "";
          backdrop-filter: blur(4px);
          transition: 250ms;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          z-index: $zindex-iconav;

          @include media-breakpoint-up(md) {
            display: none;
          }
        }
      }
    }
  }

  // new styles
  .nav-icon-container {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding: 4px 13px 0;
  }

  .side-nav-popover {
    width: 200px;
    font-weight: 400;
    font-size: 14px;
    line-height: 13px;

    .v-divider {
      margin: 8px 0;
    }

    .v-list-item__content {
      color: var(--t-color-text) !important;
    }
  }

  ::v-deep .v-navigation-drawer {
    .v-navigation-drawer__content {
      display: flex;
      flex-direction: column;
      background-color: var(--t-color-surface-raised);
      height: calc(100% - 100px);
    }
    &.has-referral{
      .v-navigation-drawer__content {
        height: calc(100% - 144px);
      }
    }
  }

  .controls-container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 8px;
  }

  // allow menus to set their own width
  .v-menu__content.v-menu__content--fixed {
    min-width: 0 !important;
    margin-left: 16px;
  }

  .account-nav{
    position:absolute;
    bottom:0;
    left:0;
    width:100%;
    background-color: var(--t-color-surface-raised);
    border-right:1px solid var(--t-color-border-weak);

    .v-list-item__title{
      font-size: 14px;
      font-weight: 500;
      line-height: 1rem;
    }
  }
}

hr {
  margin-bottom: 0px;
  margin-top: 0px;
}

::v-deep .v-navigation-drawer__border {
  background-color: var(--t-color-border-weak) !important;
}
