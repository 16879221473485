.clearfix::after {
    clear: both;
    display: block;
    content: "";
    height: 0;
}
.hide-by-clipping {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}
.custom-theme:focus {
    outline: 0;
}
.custom-theme.fr-box.fr-basic .fr-element {
    color: #52616d;
    padding: 10px;
    overflow-x: auto;
    height: 100%;
}
.custom-theme .fr-element {
    -webkit-user-select: auto;
}
.custom-theme.fr-box a.fr-floating-btn {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    height: 32px;
    width: 32px;
    background: #ffffff;
    color: #2babc4;
    -webkit-transition: background 0.2s ease 0s, color 0.2s ease 0s, transform 0.2s ease 0s;
    -moz-transition: background 0.2s ease 0s, color 0.2s ease 0s, transform 0.2s ease 0s;
    -ms-transition: background 0.2s ease 0s, color 0.2s ease 0s, transform 0.2s ease 0s;
    -o-transition: background 0.2s ease 0s, color 0.2s ease 0s, transform 0.2s ease 0s;
    left: 0;
    top: 0;
    line-height: 32px;
    border: none;
}
.custom-theme.fr-box a.fr-floating-btn svg {
    -webkit-transition: transform 0.2s ease 0s;
    -moz-transition: transform 0.2s ease 0s;
    -ms-transition: transform 0.2s ease 0s;
    -o-transition: transform 0.2s ease 0s;
    fill: #2babc4;
}
.custom-theme.fr-box a.fr-floating-btn i {
    font-size: 14px;
    line-height: 32px;
}
.custom-theme.fr-box a.fr-floating-btn:hover {
    background: #e8eaec;
}
.custom-theme.fr-box a.fr-floating-btn:hover svg {
    fill: #2babc4;
}
.custom-theme .fr-wrapper .fr-placeholder {
    color: #979797;
    top: 0;
    left: 0;
    right: 0;
}
.custom-theme .fr-wrapper ::selection {
    background: #b5d6fd;
    color: #000000;
}
.custom-theme .fr-wrapper ::-moz-selection {
    background: #b5d6fd;
    color: #000000;
}
.custom-theme.fr-box.fr-basic .fr-wrapper {
    background: #ffffff;
    border: var(--t-border-size-1) solid var(--t-color-border);
    border-top: 0;
    top: 0;
    left: 0;
}
.custom-theme.fr-box.fr-basic.fr-top .fr-wrapper {
    border-top: 0;
    border-radius: 0 0 var(--t-radius-1) var(--t-radius-1);
    -moz-border-radius: 0 0 var(--t-radius-1) var(--t-radius-1);
    -webkit-border-radius: 0 0 var(--t-radius-1) var(--t-radius-1);
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}
.custom-theme.fr-box.fr-basic.fr-bottom .fr-wrapper {
    border-bottom: 0;
    border-radius: var(--t-radius-1) var(--t-radius-1) 0 0;
    -moz-border-radius: var(--t-radius-1) var(--t-radius-1) 0 0;
    -webkit-border-radius: var(--t-radius-1) var(--t-radius-1) 0 0;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}
.custom-theme .fr-sticky-on.fr-sticky-ios {
    left: 0;
    right: 0;
}
.custom-theme.fr-box .fr-counter {
    color: #979797;
    background: #ffffff;
    border-top: solid 1px #ebebeb;
    border-left: solid 1px #ebebeb;
    border-radius: var(--t-radius-1) 0 var(--t-radius-1) 0;
    -moz-border-radius: var(--t-radius-1) 0 var(--t-radius-1) 0;
    -webkit-border-radius: var(--t-radius-1) 0 var(--t-radius-1) 0;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
}
.custom-theme.fr-box.fr-rtl .fr-counter {
    right: auto;
    border-right: solid 1px #ebebeb;
    border-radius: 0 2px 0 0;
    -moz-border-radius: 0 2px 0 0;
    -webkit-border-radius: 0 2px 0 0;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
}
.custom-theme textarea.fr-code {
    background: #ffffff;
    color: #000000;
}
.custom-theme.fr-box.fr-code-view.fr-inline {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}
.custom-theme.fr-box.fr-inline .fr-command.fr-btn.html-switch {
    top: 0;
    right: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background: #ffffff;
    color: #52616d;
    -moz-outline: 0;
    outline: 0;
    border: 0;
    padding: 12px 12px;
    -webkit-transition: background 0.2s ease 0s;
    -moz-transition: background 0.2s ease 0s;
    -ms-transition: background 0.2s ease 0s;
    -o-transition: background 0.2s ease 0s;
}
.custom-theme.fr-box.fr-inline .fr-command.fr-btn.html-switch i {
    font-size: 14px;
    width: 14px;
}
.custom-theme.fr-box.fr-inline .fr-command.fr-btn.html-switch.fr-desktop:hover {
    background: #e8eaec;
}
.custom-theme.fr-popup .fr-colors-tabs {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}
.custom-theme.fr-popup .fr-colors-tabs .fr-colors-tab {
    color: #52616d;
    padding: 8px 0;
}
.custom-theme.fr-popup .fr-colors-tabs .fr-colors-tab:hover,
.custom-theme.fr-popup .fr-colors-tabs .fr-colors-tab:focus {
    color: #2babc4;
}
.custom-theme.fr-popup .fr-colors-tabs .fr-colors-tab[data-param1="background"]::after {
    bottom: 0;
    left: 0;
    background: #2babc4;
    -webkit-transition: transform 0.2s ease 0s;
    -moz-transition: transform 0.2s ease 0s;
    -ms-transition: transform 0.2s ease 0s;
    -o-transition: transform 0.2s ease 0s;
}
.custom-theme.fr-popup .fr-colors-tabs .fr-colors-tab.fr-selected-tab {
    color: #2babc4;
}
.custom-theme.fr-popup .fr-color-set {
    line-height: 0;
}
.custom-theme.fr-popup .fr-color-set > span > i {
    bottom: 0;
    left: 0;
}
.custom-theme.fr-popup .fr-color-set > span .fr-selected-color {
    color: #ffffff;
    font-weight: 400;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}
.custom-theme.fr-popup .fr-color-set > span:hover,
.custom-theme.fr-popup .fr-color-set > span:focus {
    outline: 1px solid #52616d;
}
.custom-theme .fr-drag-helper {
    background: #2babc4;
}
.custom-theme.fr-popup .fr-link:focus {
    background: #e8eaec;
}
.custom-theme .fr-file-upload-layer {
    border: dashed 2px #bdbdbd;
    padding: 25px 0;
}
.custom-theme .fr-file-upload-layer:hover {
    background: #e8eaec;
}
.custom-theme .fr-file-upload-layer.fr-drop {
    background: #e8eaec;
    border-color: #2babc4;
}
.custom-theme .fr-file-upload-layer .fr-form {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.custom-theme .fr-file-progress-bar-layer > h3 {
    margin: 10px 0;
}
.custom-theme .fr-file-progress-bar-layer > div.fr-loader {
    background: #bfe6ed;
}
.custom-theme .fr-file-progress-bar-layer > div.fr-loader span {
    background: #2babc4;
    -webkit-transition: width 0.2s ease 0s;
    -moz-transition: width 0.2s ease 0s;
    -ms-transition: width 0.2s ease 0s;
    -o-transition: width 0.2s ease 0s;
}
.custom-theme .fr-file-progress-bar-layer > div.fr-loader.fr-indeterminate span {
    top: 0;
}
.custom-theme.fr-box.fr-fullscreen {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
.custom-theme.fr-modal .fr-modal-wrapper .fr-modal-body .fr-help-modal table tr {
    border: 0;
}
.custom-theme.fr-modal .fr-modal-wrapper .fr-modal-body .fr-help-modal table tbody tr {
    border-bottom: solid 1px #e8eaec;
}
.custom-theme.fr-modal .fr-modal-wrapper .fr-modal-body .fr-help-modal table tbody td:first-child {
    color: #869099;
}
.custom-theme .fr-image-resizer {
    border: solid 1px #2babc4;
}
.custom-theme .fr-image-resizer .fr-handler {
    background: #2babc4;
    border: solid 1px #ffffff;
}
.custom-theme .fr-image-resizer .fr-handler {
    width: 12px;
    height: 12px;
}
.custom-theme .fr-image-resizer .fr-handler.fr-hnw {
    left: -6px;
    top: -6px;
}
.custom-theme .fr-image-resizer .fr-handler.fr-hne {
    right: -6px;
    top: -6px;
}
.custom-theme .fr-image-resizer .fr-handler.fr-hsw {
    left: -6px;
    bottom: -6px;
}
.custom-theme .fr-image-resizer .fr-handler.fr-hse {
    right: -6px;
    bottom: -6px;
}
@media (min-width: 1200px) {
    .custom-theme .fr-image-resizer .fr-handler {
        width: 10px;
        height: 10px;
    }
    .custom-theme .fr-image-resizer .fr-handler.fr-hnw {
        left: -5px;
        top: -5px;
    }
    .custom-theme .fr-image-resizer .fr-handler.fr-hne {
        right: -5px;
        top: -5px;
    }
    .custom-theme .fr-image-resizer .fr-handler.fr-hsw {
        left: -5px;
        bottom: -5px;
    }
    .custom-theme .fr-image-resizer .fr-handler.fr-hse {
        right: -5px;
        bottom: -5px;
    }
}
.custom-theme.fr-image-overlay {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
.custom-theme .fr-image-upload-layer {
    border: dashed 2px #bdbdbd;
    padding: 25px 0;
}
.custom-theme .fr-image-upload-layer:hover {
    background: #e8eaec;
}
.custom-theme .fr-image-upload-layer.fr-drop {
    background: #e8eaec;
    border-color: #2babc4;
}
.custom-theme .fr-image-upload-layer .fr-form {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.custom-theme .fr-image-progress-bar-layer > h3 {
    margin: 10px 0;
}
.custom-theme .fr-image-progress-bar-layer > div.fr-loader {
    background: #bfe6ed;
}
.custom-theme .fr-image-progress-bar-layer > div.fr-loader span {
    background: #2babc4;
    -webkit-transition: width 0.2s ease 0s;
    -moz-transition: width 0.2s ease 0s;
    -ms-transition: width 0.2s ease 0s;
    -o-transition: width 0.2s ease 0s;
}
.custom-theme .fr-image-progress-bar-layer > div.fr-loader.fr-indeterminate span {
    top: 0;
}
.custom-theme.fr-modal-head .fr-modal-head-line i.fr-modal-more {
    -webkit-transition: padding 0.2s ease 0s, width 0.2s ease 0s, opacity 0.2s ease 0s;
    -moz-transition: padding 0.2s ease 0s, width 0.2s ease 0s, opacity 0.2s ease 0s;
    -ms-transition: padding 0.2s ease 0s, width 0.2s ease 0s, opacity 0.2s ease 0s;
    -o-transition: padding 0.2s ease 0s, width 0.2s ease 0s, opacity 0.2s ease 0s;
}
.custom-theme.fr-modal-head .fr-modal-head-line i.fr-modal-more.fr-not-available {
    opacity: 0;
    width: 0;
    padding: 12px 0;
}
.custom-theme.fr-modal-head .fr-modal-tags a {
    opacity: 0;
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    color: #2babc4;
    -webkit-transition: opacity 0.2s ease 0s, background 0.2s ease 0s;
    -moz-transition: opacity 0.2s ease 0s, background 0.2s ease 0s;
    -ms-transition: opacity 0.2s ease 0s, background 0.2s ease 0s;
    -o-transition: opacity 0.2s ease 0s, background 0.2s ease 0s;
}
.custom-theme.fr-modal-head .fr-modal-tags a.fr-selected-tag {
    background: #d2d6d9;
}
.custom-themediv.fr-modal-body .fr-preloader {
    margin: 50px auto;
}
.custom-themediv.fr-modal-body div.fr-image-list {
    padding: 0;
}
.custom-themediv.fr-modal-body div.fr-image-list div.fr-image-container {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
}
.custom-themediv.fr-modal-body div.fr-image-list div.fr-image-container.fr-image-deleting::after {
    -webkit-transition: opacity 0.2s ease 0s;
    -moz-transition: opacity 0.2s ease 0s;
    -ms-transition: opacity 0.2s ease 0s;
    -o-transition: opacity 0.2s ease 0s;
    background: #52616d;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
.custom-themediv.fr-modal-body div.fr-image-list div.fr-image-container.fr-image-deleting::before {
    color: #ffffff;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
}
.custom-themediv.fr-modal-body div.fr-image-list div.fr-image-container.fr-empty {
    background: #979797;
}
.custom-themediv.fr-modal-body div.fr-image-list div.fr-image-container.fr-empty::after {
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.custom-themediv.fr-modal-body div.fr-image-list div.fr-image-container img {
    -webkit-transition: opacity 0.2s ease 0s, filter 0.2s ease 0s;
    -moz-transition: opacity 0.2s ease 0s, filter 0.2s ease 0s;
    -ms-transition: opacity 0.2s ease 0s, filter 0.2s ease 0s;
    -o-transition: opacity 0.2s ease 0s, filter 0.2s ease 0s;
}
.custom-themediv.fr-modal-body div.fr-image-list div.fr-image-container .fr-delete-img,
.custom-themediv.fr-modal-body div.fr-image-list div.fr-image-container .fr-insert-img {
    -webkit-transition: background 0.2s ease 0s, color 0.2s ease 0s;
    -moz-transition: background 0.2s ease 0s, color 0.2s ease 0s;
    -ms-transition: background 0.2s ease 0s, color 0.2s ease 0s;
    -o-transition: background 0.2s ease 0s, color 0.2s ease 0s;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    margin: 0;
}
.custom-themediv.fr-modal-body div.fr-image-list div.fr-image-container .fr-delete-img {
    background: #b8312f;
    color: #ffffff;
}
.custom-themediv.fr-modal-body div.fr-image-list div.fr-image-container .fr-insert-img {
    background: #f2f3f3;
    color: #2babc4;
}
.custom-theme.custom-theme.fr-desktop .fr-modal-wrapper .fr-modal-head .fr-modal-tags a:hover {
    background: #e8eaec;
}
.custom-theme.custom-theme.fr-desktop .fr-modal-wrapper .fr-modal-head .fr-modal-tags a.fr-selected-tag {
    background: #d2d6d9;
}
.custom-theme.custom-theme.fr-desktop .fr-modal-wrapper div.fr-modal-body div.fr-image-list div.fr-image-container .fr-delete-img:hover {
    background: #bf4644;
    color: #ffffff;
}
.custom-theme.custom-theme.fr-desktop .fr-modal-wrapper div.fr-modal-body div.fr-image-list div.fr-image-container .fr-insert-img:hover {
    background: #e8eaec;
}
.custom-theme .fr-line-breaker {
    border-top: 1px solid #2babc4;
}
.custom-theme .fr-line-breaker a.fr-floating-btn {
    left: calc(34%);
    top: -16px;
}
.custom-theme .fr-qi-helper {
    padding-left: 10px;
}
.custom-theme .fr-qi-helper a.fr-btn.fr-floating-btn {
    color: #52616d;
}
.custom-theme.fr-modal .fr-modal-wrapper .fr-modal-body .fr-special-characters-modal .fr-special-character {
    border: var(--t-border-size-1) solid var(--t-color-border);
}
.custom-theme .fr-element table td.fr-selected-cell,
.custom-theme .fr-element table th.fr-selected-cell {
    border: 1px double #2babc4;
}
.custom-theme .fr-table-resizer div {
    border-right: 1px solid #2babc4;
}
.custom-theme.fr-popup .fr-table-size .fr-select-table-size {
    line-height: 0;
}
.custom-theme.fr-popup .fr-table-size .fr-select-table-size > span {
    padding: 0px 4px 4px 0;
}
.custom-theme.fr-popup .fr-table-size .fr-select-table-size > span > span {
    border: 1px solid #52616d;
}
.custom-theme.fr-popup .fr-table-size .fr-select-table-size > span.hover > span {
    background: rgba(43, 171, 196, 0.3);
    border: solid 1px #2babc4;
}
.custom-theme.fr-popup .fr-table-colors {
    line-height: 0;
}
.custom-theme.fr-popup .fr-table-colors > span > i {
    bottom: 0;
    left: 0;
}
.custom-theme.fr-popup .fr-table-colors > span:focus {
    outline: 1px solid #52616d;
}
.custom-theme .fr-element .fr-video::after {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.custom-theme.fr-box .fr-video-resizer {
    border: solid 1px #2babc4;
}
.custom-theme.fr-box .fr-video-resizer .fr-handler {
    background: #2babc4;
    border: solid 1px #ffffff;
}
.custom-theme.fr-box .fr-video-resizer .fr-handler {
    width: 12px;
    height: 12px;
}
.custom-theme.fr-box .fr-video-resizer .fr-handler.fr-hnw {
    left: -6px;
    top: -6px;
}
.custom-theme.fr-box .fr-video-resizer .fr-handler.fr-hne {
    right: -6px;
    top: -6px;
}
.custom-theme.fr-box .fr-video-resizer .fr-handler.fr-hsw {
    left: -6px;
    bottom: -6px;
}
.custom-theme.fr-box .fr-video-resizer .fr-handler.fr-hse {
    right: -6px;
    bottom: -6px;
}
@media (min-width: 1200px) {
    .custom-theme.fr-box .fr-video-resizer .fr-handler {
        width: 10px;
        height: 10px;
    }
    .custom-theme.fr-box .fr-video-resizer .fr-handler.fr-hnw {
        left: -5px;
        top: -5px;
    }
    .custom-theme.fr-box .fr-video-resizer .fr-handler.fr-hne {
        right: -5px;
        top: -5px;
    }
    .custom-theme.fr-box .fr-video-resizer .fr-handler.fr-hsw {
        left: -5px;
        bottom: -5px;
    }
    .custom-theme.fr-box .fr-video-resizer .fr-handler.fr-hse {
        right: -5px;
        bottom: -5px;
    }
}
.custom-theme .fr-video-upload-layer {
    border: dashed 2px #bdbdbd;
    padding: 25px 0;
}
.custom-theme .fr-video-upload-layer:hover {
    background: #e8eaec;
}
.custom-theme .fr-video-upload-layer.fr-drop {
    background: #e8eaec;
    border-color: #2babc4;
}
.custom-theme .fr-video-upload-layer .fr-form {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.custom-theme .fr-video-progress-bar-layer > h3 {
    margin: 10px 0;
}
.custom-theme .fr-video-progress-bar-layer > div.fr-loader {
    background: #bfe6ed;
}
.custom-theme .fr-video-progress-bar-layer > div.fr-loader span {
    background: #2babc4;
    -webkit-transition: width 0.2s ease 0s;
    -moz-transition: width 0.2s ease 0s;
    -ms-transition: width 0.2s ease 0s;
    -o-transition: width 0.2s ease 0s;
}
.custom-theme .fr-video-progress-bar-layer > div.fr-loader.fr-indeterminate span {
    top: 0;
}
.custom-theme.fr-video-overlay {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
.custom-theme .fr-view strong {
    font-weight: 700;
}
.custom-theme .fr-view table.fr-alternate-rows tbody tr:nth-child(2n) {
    background: #e9ebec;
}
.custom-theme .fr-view table td,
.custom-theme .fr-view table th {
    border: 1px solid #52616d;
}
.custom-theme .fr-view table th {
    background: #eeeff0;
}
.custom-theme .fr-view[dir="rtl"] blockquote {
    border-right: solid 2px #5e35b1;
    margin-right: 0;
}
.custom-theme .fr-view[dir="rtl"] blockquote blockquote {
    border-color: #2babc4;
}
.custom-theme .fr-view[dir="rtl"] blockquote blockquote blockquote {
    border-color: #9cc658;
}
.custom-theme .fr-view blockquote {
    border-left: solid 2px #5e35b1;
    margin-left: 0;
    color: #5e35b1;
}
.custom-theme .fr-view blockquote blockquote {
    border-color: #2babc4;
    color: #2babc4;
}
.custom-theme .fr-view blockquote blockquote blockquote {
    border-color: #9cc658;
    color: #9cc658;
}
.custom-theme .fr-view span.fr-emoticon {
    line-height: 0;
}
.custom-theme .fr-view span.fr-emoticon.fr-emoticon-img {
    font-size: inherit;
}
.custom-theme .fr-view .fr-text-bordered {
    padding: 10px 0;
}
.custom-theme .fr-tooltip {
    top: 0;
    left: 0;
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background: padding-box #52616d;
    color: #ffffff;
    font-size: 11px;
    line-height: 22px;
    font-family: Arial, Helvetica, sans-serif;
    -webkit-transition: opacity 0.2s ease 0s;
    -moz-transition: opacity 0.2s ease 0s;
    -ms-transition: opacity 0.2s ease 0s;
    -o-transition: opacity 0.2s ease 0s;
}
.custom-theme.fr-toolbar .fr-command.fr-btn,
.custom-theme.fr-popup .fr-command.fr-btn {
    color: #52616d;
    -moz-outline: 0;
    outline: 0;
    border: 0;
    margin: 0px 2px;
    -webkit-transition: background 0.2s ease 0s;
    -moz-transition: background 0.2s ease 0s;
    -ms-transition: background 0.2s ease 0s;
    -o-transition: background 0.2s ease 0s;
    padding: 0;
    width: 38px;
    height: 38px;
}
.custom-theme.fr-toolbar .fr-command.fr-btn::-moz-focus-inner,
.custom-theme.fr-popup .fr-command.fr-btn::-moz-focus-inner {
    border: 0;
}
.custom-theme.fr-toolbar .fr-command.fr-btn.fr-btn-text,
.custom-theme.fr-popup .fr-command.fr-btn.fr-btn-text {
    width: auto;
}
.custom-theme.fr-toolbar .fr-command.fr-btn i,
.custom-theme.fr-popup .fr-command.fr-btn i {
    font-size: 14px;
    width: 14px;
    margin: 12px 12px;
}
.custom-theme.fr-toolbar .fr-command.fr-btn span,
.custom-theme.fr-popup .fr-command.fr-btn span {
    font-size: 14px;
    line-height: 17px;
    min-width: 38px;
    height: 17px;
    padding: 0 2px;
}
.custom-theme.fr-toolbar .fr-command.fr-btn img,
.custom-theme.fr-popup .fr-command.fr-btn img {
    margin: 12px 12px;
    width: 14px;
}
.custom-theme.fr-toolbar .fr-command.fr-btn.fr-active,
.custom-theme.fr-popup .fr-command.fr-btn.fr-active {
    color: #2babc4;
    background: transparent;
}
.custom-theme.fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-selection,
.custom-theme.fr-popup .fr-command.fr-btn.fr-dropdown.fr-selection {
    width: auto;
}
.custom-theme.fr-toolbar .fr-command.fr-btn.fr-dropdown i,
.custom-theme.fr-popup .fr-command.fr-btn.fr-dropdown i,
.custom-theme.fr-toolbar .fr-command.fr-btn.fr-dropdown span,
.custom-theme.fr-popup .fr-command.fr-btn.fr-dropdown span,
.custom-theme.fr-toolbar .fr-command.fr-btn.fr-dropdown img,
.custom-theme.fr-popup .fr-command.fr-btn.fr-dropdown img {
    margin-left: 8px;
    margin-right: 16px;
}
.custom-theme.fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-active,
.custom-theme.fr-popup .fr-command.fr-btn.fr-dropdown.fr-active {
    color: #52616d;
    background: #d2d6d9;
}
.custom-theme.fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-active:hover,
.custom-theme.fr-popup .fr-command.fr-btn.fr-dropdown.fr-active:hover,
.custom-theme.fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-active:focus,
.custom-theme.fr-popup .fr-command.fr-btn.fr-dropdown.fr-active:focus {
    background: #d2d6d9 !important;
    color: #52616d !important;
}
.custom-theme.fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-active:hover::after,
.custom-theme.fr-popup .fr-command.fr-btn.fr-dropdown.fr-active:hover::after,
.custom-theme.fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-active:focus::after,
.custom-theme.fr-popup .fr-command.fr-btn.fr-dropdown.fr-active:focus::after {
    border-top-color: #52616d !important;
}
.custom-theme.fr-toolbar .fr-command.fr-btn.fr-dropdown::after,
.custom-theme.fr-popup .fr-command.fr-btn.fr-dropdown::after {
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #52616d;
    right: 4px;
    top: 17px;
}
.custom-theme.fr-toolbar .fr-command.fr-btn.fr-disabled,
.custom-theme.fr-popup .fr-command.fr-btn.fr-disabled {
    color: #bdbdbd;
}
.custom-theme.fr-toolbar .fr-command.fr-btn.fr-disabled::after,
.custom-theme.fr-popup .fr-command.fr-btn.fr-disabled::after {
    border-top-color: #bdbdbd !important;
}
.custom-theme.fr-toolbar.fr-disabled .fr-btn,
.custom-theme.fr-popup.fr-disabled .fr-btn,
.custom-theme.fr-toolbar.fr-disabled .fr-btn.fr-active,
.custom-theme.fr-popup.fr-disabled .fr-btn.fr-active {
    color: #bdbdbd;
}
.custom-theme.fr-toolbar.fr-disabled .fr-btn.fr-dropdown::after,
.custom-theme.fr-popup.fr-disabled .fr-btn.fr-dropdown::after,
.custom-theme.fr-toolbar.fr-disabled .fr-btn.fr-active.fr-dropdown::after,
.custom-theme.fr-popup.fr-disabled .fr-btn.fr-active.fr-dropdown::after {
    border-top-color: #bdbdbd;
}
.custom-theme.fr-desktop .fr-command:hover,
.custom-theme.fr-desktop .fr-command:focus {
    color: #222222;
    background: #e8eaec;
}
.custom-theme.fr-desktop .fr-command:hover::after,
.custom-theme.fr-desktop .fr-command:focus::after {
    border-top-color: #222222 !important;
}
.custom-theme.fr-desktop .fr-command.fr-selected {
    color: #52616d;
    background: #d2d6d9;
}
.custom-theme.fr-desktop .fr-command.fr-active:hover,
.custom-theme.fr-desktop .fr-command.fr-active:focus {
    color: #2babc4;
    background: #e8eaec;
}
.custom-theme.fr-desktop .fr-command.fr-active.fr-selected {
    color: #2babc4;
    background: #d2d6d9;
}
.custom-theme.fr-toolbar.fr-mobile .fr-command.fr-blink,
.custom-theme.fr-popup.fr-mobile .fr-command.fr-blink {
    background: transparent;
}
.custom-theme .fr-command.fr-btn + .fr-dropdown-menu {
    right: auto;
    bottom: auto;
    height: auto;
    border-radius: 0 0 2px 2px;
    -moz-border-radius: 0 0 2px 2px;
    -webkit-border-radius: 0 0 2px 2px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
}
.custom-theme .fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper {
    background: #f2f3f3;
    padding: 0;
    margin: auto;
    -webkit-transition: max-height 0.2s ease 0s;
    -moz-transition: max-height 0.2s ease 0s;
    -ms-transition: max-height 0.2s ease 0s;
    -o-transition: max-height 0.2s ease 0s;
    margin-top: 0;
    max-height: 0;
    height: 0;
}
.custom-theme .fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content {
    overflow: auto;
    max-height: 275px;
}
.custom-theme .fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list {
    margin: 0;
    padding: 0;
}
.custom-theme .fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list li {
    padding: 0;
    margin: 0;
}
.custom-theme .fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list li a {
    color: inherit;
}
.custom-theme .fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list li a.fr-active {
    background: #d2d6d9;
}
.custom-theme .fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list li a.fr-disabled {
    color: #bdbdbd;
}
.custom-theme .fr-command.fr-btn.fr-active + .fr-dropdown-menu {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}
.custom-theme .fr-command.fr-btn.fr-active + .fr-dropdown-menu .fr-dropdown-wrapper {
    height: auto;
    max-height: 275px;
}
.custom-theme .fr-bottom > .fr-command.fr-btn + .fr-dropdown-menu {
    border-radius: 2px 2px 0 0;
    -moz-border-radius: 2px 2px 0 0;
    -webkit-border-radius: 2px 2px 0 0;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}
.custom-theme.fr-modal {
    color: #52616d;
    font-family: Arial, Helvetica, sans-serif;
    overflow-x: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
.custom-theme.fr-modal .fr-modal-wrapper {
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    margin: 20px auto;
    background: padding-box #ffffff;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border: var(--t-border-size-1) solid var(--t-color-border);
}
@media (min-width: 768px) and (max-width: 991px) {
    .custom-theme.fr-modal .fr-modal-wrapper {
        margin: 30px auto;
    }
}
@media (min-width: 992px) {
    .custom-theme.fr-modal .fr-modal-wrapper {
        margin: 50px auto;
    }
}
.custom-theme.fr-modal .fr-modal-wrapper .fr-modal-head {
    background: #f2f3f3;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border-bottom: var(--t-border-size-1) solid var(--t-color-border);
    -webkit-transition: height 0.2s ease 0s;
    -moz-transition: height 0.2s ease 0s;
    -ms-transition: height 0.2s ease 0s;
    -o-transition: height 0.2s ease 0s;
}
.custom-theme.fr-modal .fr-modal-wrapper .fr-modal-head i {
    color: #52616d;
}
.custom-theme.fr-modal .fr-modal-wrapper .fr-modal-head i.fr-modal-close {
    top: 0;
    right: 0;
    -webkit-transition: color 0.2s ease 0s;
    -moz-transition: color 0.2s ease 0s;
    -ms-transition: color 0.2s ease 0s;
    -o-transition: color 0.2s ease 0s;
}
.custom-theme.fr-modal .fr-modal-wrapper .fr-modal-head h4 {
    margin: 0;
    font-weight: 400;
}
.custom-theme.custom-theme.fr-desktop .fr-modal-wrapper .fr-modal-head i:hover {
    background: #e8eaec;
}
.custom-theme.fr-overlay {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #000000;
}
.custom-theme.fr-popup {
    color: #52616d;
    background: #f2f3f3;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    font-family: Arial, Helvetica, sans-serif;
    border: var(--t-border-size-1) solid var(--t-color-border);
}
.custom-theme.fr-popup .fr-input-focus {
    background: #f5f5f5;
}
.custom-theme.fr-popup.fr-above {
    border-top: 0;
    border-bottom: var(--t-border-size-1) solid var(--t-color-border);
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}
.custom-theme.fr-popup .fr-input-line {
    padding: 8px 0;
}
.custom-theme.fr-popup .fr-input-line input[type="text"],
.custom-theme.fr-popup .fr-input-line textarea {
    margin: 0px 0 1px 0;
    border-bottom: solid 1px #bdbdbd;
    color: #52616d;
}
.custom-theme.fr-popup .fr-input-line input[type="text"]:focus,
.custom-theme.fr-popup .fr-input-line textarea:focus {
    border-bottom: solid 2px #2babc4;
}
.custom-theme.fr-popup .fr-input-line input + label,
.custom-theme.fr-popup .fr-input-line textarea + label {
    top: 0;
    left: 0;
    -webkit-transition: color 0.2s ease 0s;
    -moz-transition: color 0.2s ease 0s;
    -ms-transition: color 0.2s ease 0s;
    -o-transition: color 0.2s ease 0s;
    background: #f2f3f3;
}
.custom-theme.fr-popup .fr-input-line input.fr-not-empty:focus + label,
.custom-theme.fr-popup .fr-input-line textarea.fr-not-empty:focus + label {
    color: #2babc4;
}
.custom-theme.fr-popup .fr-input-line input.fr-not-empty + label,
.custom-theme.fr-popup .fr-input-line textarea.fr-not-empty + label {
    color: #808080;
}
.custom-theme.fr-popup .fr-buttons {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    padding: 0 2px;
    line-height: 0;
    border-bottom: var(--t-border-size-1) solid var(--t-color-border);
}
.custom-theme.fr-popup .fr-layer {
    width: 225px;
}
@media (min-width: 768px) {
    .custom-theme.fr-popup .fr-layer {
        width: 300px;
    }
}
.custom-theme.fr-popup .fr-action-buttons button.fr-command {
    color: #2babc4;
    -webkit-transition: background 0.2s ease 0s;
    -moz-transition: background 0.2s ease 0s;
    -ms-transition: background 0.2s ease 0s;
    -o-transition: background 0.2s ease 0s;
}
.custom-theme.fr-popup .fr-action-buttons button.fr-command:hover,
.custom-theme.fr-popup .fr-action-buttons button.fr-command:focus {
    background: #e8eaec;
    color: #2babc4;
}
.custom-theme.fr-popup .fr-action-buttons button.fr-command:active {
    background: #d2d6d9;
    color: #2babc4;
}
.custom-theme.fr-popup .fr-action-buttons button::-moz-focus-inner {
    border: 0;
}
.custom-theme.fr-popup .fr-checkbox span {
    border: solid 1px #52616d;
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    -webkit-transition: background 0.2s ease 0s, border-color 0.2s ease 0s;
    -moz-transition: background 0.2s ease 0s, border-color 0.2s ease 0s;
    -ms-transition: background 0.2s ease 0s, border-color 0.2s ease 0s;
    -o-transition: background 0.2s ease 0s, border-color 0.2s ease 0s;
}
.custom-theme.fr-popup .fr-checkbox input {
    margin: 0;
    padding: 0;
}
.custom-theme.fr-popup .fr-checkbox input:checked + span {
    background: #2babc4;
    border-color: #2babc4;
}
.custom-theme.fr-popup .fr-checkbox input:focus + span {
    border-color: #2babc4;
}
.custom-theme.fr-popup.fr-rtl .fr-input-line input + label,
.custom-theme.fr-popup.fr-rtl .fr-input-line textarea + label {
    left: auto;
    right: 0;
}
.custom-theme.fr-popup .fr-arrow {
    width: 0;
    height: 0;
    border-left: var(--t-border-size-3) solid transparent;
    border-right: var(--t-border-size-3) solid transparent;
    border-bottom: var(--t-border-size-3) solid var(--t-color-border);
    top: -9px;
    margin-left: -5px;
}
.custom-theme.fr-popup.fr-above .fr-arrow {
    top: auto;
    bottom: -9px;
    border-bottom: 0;
    border-top: var(--t-border-size-3) solid var(--t-color-border);
}
.custom-theme .fr-toolbar {
    color: #52616d;
    background: #f2f3f3;
    font-family: Arial, Helvetica, sans-serif;
    padding: 0;
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border: var(--t-border-size-1) solid var(--t-color-border);
}
.custom-theme .fr-toolbar.fr-inline .fr-arrow {
    width: 0;
    height: 0;
    border-left: var(--t-border-size-3) solid transparent;
    border-right: var(--t-border-size-3) solid transparent;
    border-bottom: var(--t-border-size-3) solid var(--t-color-border);
    top: -9px;
    margin-left: -5px;
}
.custom-theme .fr-toolbar.fr-inline.fr-above {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border-bottom: var(--t-border-size-1) solid var(--t-color-border);
    border-top: 0;
}
.custom-theme .fr-toolbar.fr-inline.fr-above .fr-arrow {
    top: auto;
    bottom: -9px;
    border-bottom: 0;
    border-top-color: inherit;
    border-top-width: 5px;
}
.custom-theme .fr-toolbar.fr-top {
    top: 0;
    border-radius: var(--t-radius-1) var(--t-radius-1) 0 0;
    -moz-border-radius: var(--t-radius-1) var(--t-radius-1) 0 0;
    -webkit-border-radius: var(--t-radius-1) var(--t-radius-1) 0 0;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    min-height: 42px;
}
.custom-theme .fr-toolbar.fr-bottom {
    bottom: 0;
    border-radius: 0 0 2px 2px;
    -moz-border-radius: 0 0 2px 2px;
    -webkit-border-radius: 0 0 2px 2px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}
.custom-theme .fr-separator {
    background: #e8eaec;
}
.custom-theme .fr-separator.fr-vs {
    height: 40px;
    width: 1px;
    margin: 0;
}
.custom-theme .fr-separator.fr-hs {
    height: 1px;
    width: calc(96%);
    margin: 0 2px;
}

.custom-theme .fr-wrapper {
    min-height: 150px;
    height: 150px;
}

.custom-layer {
    text-align: center;
    padding: 10px;
}
.tango-fr-plugin-list {
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 0;
    width: 178px;
    border: var(--t-border-size-1) solid var(--t-color-border);
    border-top: 0;
    border-radius: 0 0 2px 2px;
    color: #52616D;
}
.tango-fr-plugin-list li {
    list-style: none;
    padding: 10px;
    margin-left: -5px;
    margin-right: -5px;
    border-bottom: var(--t-border-size-1) solid var(--t-color-border);
    text-align: left;
    font-weight: 500 !important;
}
.tango-fr-plugin-list li:last-child {
    border-bottom: 0;
}
.tango-fr-plugin-list li:hover {
    cursor: pointer;
    background-color: #F2F3F3;
}

.fr-btn-mtIconTemplate {
    width: fit-content !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
    border: var(--t-border-size-1) solid var(--t-color-border) !important;
    border-radius: var(--t-radius-1) !important;
    text-align: center !important;
    height: 32px !important;
    margin: 4px !important;
    font-size: 14px !important;
    color: var(--t-color-text) !important;
    float: right !important;
}

.fr-editor-basic.custom-theme .fr-wrapper {
    min-height: 78px !important;
    height: 78px; /* DO NOT REMOVE. NEEDED FOR EDITOR CLICK FOCUS */
}

.fr-editor-basic.custom-theme .fr-toolbar button.fr-btn-mtIconTemplate{
    float: right;
}

.custom-theme .fr-popup {
    border-top: 5px solid #979797;
}

.custom-theme .fr-popup .fr-arrow {
    left: 82.5%;
    border-bottom: 5px solid #979797;
}

.fr-toolbar .fr-command.fr-btn i.tci {
    margin: auto;
}
.custom-theme .fr-toolbar .fr-command.fr-btn, .fr-popup .fr-command.fr-btn {
    height: 40px;
}

.fr-toolbar.fr-desktop.fr-inline {
    z-index: 100000000 !important;
}

.fr-popup {
    z-index: 100000000 !important;
}
