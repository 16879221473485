

.tc-banner-message{
  background-color: var(--t-color-primary-weakest);
  border-radius:0.5rem;
  padding: 0.75rem;
  margin: 1.5rem auto;
  white-space:normal;
  &.message-warning{
    background-color: var(--t-color-status-warning-weak);
  }
  &.message-error{
    background-color: var(--t-color-status-danger-weakest);
  }
  &.message-info{
    background-color: var(--t-color-primary-weakest);
  }
  &.message-success{
    background-color: var(--t-color-status-success-weakest);
  }
  .v-icon {
    color:var(--t-color-text);
  }
  ::v-deep a{
    font-weight: var(--t-font-weight-5) !important;
    color: var(--t-color-text-link) !important;;
    text-decoration: underline !important;;
  }
}

.icon-col{
  width:35px;
  max-width: 35px;
  flex-grow: 1;
}

.message-title{
  height: auto;
}

.login-error{
  padding-left:32px;
}

.banner-bottom-btns {
  display: flex;
  gap: 0.5rem;
}

.tc-banner-bottom-btn {
  color: var(--t-color-text);
  cursor: pointer;
  text-decoration: underline;

  ::v-deep span {
    padding: 0 0.5rem;
  }

  &:first-child {
    // Offset the above 0.5rem padding so that it lines up on the left side
    margin-left: -0.5rem !important;
  }
}
