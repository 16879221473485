html {
  --t-shadow-color: 220 3% 15%;   
  --t-shadow-strength: 1%;
  --t-shadow-1: 0 1px 2px -1px hsl(var(--t-shadow-color) / calc(var(--t-shadow-strength) + 9%));
  --t-shadow-2: 
    0 3px 5px -2px hsl(var(--t-shadow-color) / calc(var(--t-shadow-strength) + 3%)),
    0 7px 14px -5px hsl(var(--t-shadow-color) / calc(var(--t-shadow-strength) + 5%));
  --t-shadow-3: 
    0 -1px 3px 0 hsl(var(--t-shadow-color) / calc(var(--t-shadow-strength) + 2%)),
    0 1px 2px -5px hsl(var(--t-shadow-color) / calc(var(--t-shadow-strength) + 2%)),
    0 2px 5px -5px hsl(var(--t-shadow-color) / calc(var(--t-shadow-strength) + 4%)),
    0 4px 12px -5px hsl(var(--t-shadow-color) / calc(var(--t-shadow-strength) + 5%)),
    0 12px 15px -5px hsl(var(--t-shadow-color) / calc(var(--t-shadow-strength) + 7%));
  --t-shadow-4: 
    0 -2px 5px 0 hsl(var(--t-shadow-color) / calc(var(--t-shadow-strength) + 2%)),
    0 1px 1px -2px hsl(var(--t-shadow-color) / calc(var(--t-shadow-strength) + 3%)),
    0 2px 2px -2px hsl(var(--t-shadow-color) / calc(var(--t-shadow-strength) + 3%)),
    0 5px 5px -2px hsl(var(--t-shadow-color) / calc(var(--t-shadow-strength) + 4%)),
    0 9px 9px -2px hsl(var(--t-shadow-color) / calc(var(--t-shadow-strength) + 5%)),
    0 16px 16px -2px hsl(var(--t-shadow-color) / calc(var(--t-shadow-strength) + 6%));
  --t-shadow-5: 
    0 -1px 2px 0 hsl(var(--t-shadow-color) / calc(var(--t-shadow-strength) + 2%)),
    0 2px 1px -2px hsl(var(--t-shadow-color) / calc(var(--t-shadow-strength) + 3%)),
    0 5px 5px -2px hsl(var(--t-shadow-color) / calc(var(--t-shadow-strength) + 3%)),
    0 10px 10px -2px hsl(var(--t-shadow-color) / calc(var(--t-shadow-strength) + 4%)),
    0 20px 20px -2px hsl(var(--t-shadow-color) / calc(var(--t-shadow-strength) + 5%)),
    0 40px 40px -2px hsl(var(--t-shadow-color) / calc(var(--t-shadow-strength) + 7%));
  --t-shadow-6: 
    0 -1px 2px 0 hsl(var(--t-shadow-color) / calc(var(--t-shadow-strength) + 2%)),
    0 3px 2px -2px hsl(var(--t-shadow-color) / calc(var(--t-shadow-strength) + 3%)),
    0 7px 5px -2px hsl(var(--t-shadow-color) / calc(var(--t-shadow-strength) + 3%)),
    0 12px 10px -2px hsl(var(--t-shadow-color) / calc(var(--t-shadow-strength) + 4%)),
    0 22px 18px -2px hsl(var(--t-shadow-color) / calc(var(--t-shadow-strength) + 5%)),
    0 41px 33px -2px hsl(var(--t-shadow-color) / calc(var(--t-shadow-strength) + 6%)),
    0 100px 80px -2px hsl(var(--t-shadow-color) / calc(var(--t-shadow-strength) + 7%));
}
