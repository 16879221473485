.t-input {
  border: 1px solid var(--t-color-border);
  border-radius: 4px;
  padding: 8px;
}

.t-input-select {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23131313%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 0.7rem top 50%;
  background-size: 0.65rem auto;
  padding-inline-end: 2rem;
  text-overflow: ellipsis;
}

#app input.t-input-checkbox {
  margin-block-start: 1.25rem;
  display: flex;
  align-items: baseline;
  gap: .5rem;
}

// Input
.form-control-sm {
  font-size: $font-size-base;
}

// Select
select.form-control {
  appearance: none;
  /* SVG background caret image */
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23757575'><polygon points='0,0 100,0 50,45'/></svg>") no-repeat;
  background-size: 12px;
  background-position: calc(100% - 16px) calc(50% + 3px);
  background-repeat: no-repeat;
}

// Datepicker
.mx-datepicker {
  .mx-input {
    box-shadow: none;
    border-radius: $border-radius;
    height: $input-height-inner + 2;
    border-color: $input-border-color;
  }
}

// Errors
.validation-error, .has-danger {
  color: $danger;
}

// Option Toggle
.option-toggle {
  max-width: 75%;
  line-height: 20px;
  font-size: 14px;
  color: $primary;
  margin-bottom: 24px;
}

.radio-checkmark, .checkbox-checkmark {
  & > input {
    display: none;
    &:disabled + label {
      opacity: .65;
      &:hover {
        cursor: not-allowed;
      }
    }
  }
}

.radio-checkmark {
  & > label {
    display: inline-block;
    width: 36px;
    height: 36px;
    background: $white;
    border-radius: 50%;
    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
    transform: rotate(45deg);
    vertical-align: middle;
    padding: 5px;
    border: solid 1px $gray-light;
    cursor: pointer;
  }

  & > input:checked + label {
    background: $success;
    border: none;

    &:before {
      content: "";
      position: absolute;
      width: 3px;
      height: 22px;
      background-color: #fff;
      left: 18px;
      top: 3px;
    }

    &:after {
      content: "";
      position: absolute;
      width: 12px;
      height: 3px;
      background-color: #fff;
      left: 9px;
      top: 24px;
    }

  }
}

.checkbox-checkmark {
  & > label {
    display:inline-block;
    width: 22px;
    height:22px;
    background: $white;
    vertical-align: middle;
    padding: 5px;
    border: solid 1px $gray-light;
    cursor: pointer;
    position: relative;
  }

  & > input:checked + label {
    background: $success;
    border: none;

    &:before{
      content:"";
      position: absolute;
      -ms-transform: rotate(45deg); /* IE 9 */
      -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
      transform: rotate(45deg);
      width:2px;
      height:10px;
      background-color:#fff;
      left:50%;
      top:50%;
      margin-top: -5px;
    }

    &:after{
      content:"";
      position: absolute;
      -ms-transform: rotate(45deg); /* IE 9 */
      -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
      transform: rotate(45deg);
      width:6px;
      height:2px;
      background-color:#fff;
      left:50%;
      top:50%;
      margin-left: -6px;
    }

  }

}


// Checkmark
.checkmark {
  display:inline-block;
  width: 22px;
  height:22px;
  background: $success;
  border-radius:50%;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
  transform: rotate(45deg);
  vertical-align: middle;
  padding: 5px;

  &:before{
    content:"";
    position: absolute;
    width:3px;
    height:9px;
    background-color:#fff;
    left:11px;
    top:6px;
  }

  &:after{
    content:"";
    position: absolute;
    width:3px;
    height:3px;
    background-color:#fff;
    left:8px;
    top:12px;
  }

}

.t-input-checkbox {
  display: flex;
  align-items: baseline;
  gap: .5rem;

  .v-input .v-label {
    font-size: 14px;
    margin: 0;
    padding: 0 4px;
  }
}


