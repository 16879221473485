.t-primary-text {
  color: var(--t-color-primary);
}

.t-input {
  border-radius: 0;
  border-color: var(--t-color-border);
}

/**
MARGINS AND PADDING
 */
// NOTE: wherever applicable, please use bootstrap margin and padding utilities:
// https://getbootstrap.com/docs/4.0/utilities/spacing/
// Our $spacer var is set to 20px
// below is for edge cases where bootstrap utils won't cut it.
// Only add more pixel counts if you absolutely need them.
$spaceamounts: (15, 16, 40, 50); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone
@each $space in $spaceamounts {
  @each $side in $sides {
    .margin-#{$side}-#{$space} { // ex. margin-top-16
      margin-#{$side}: #{$space}px !important;
    }

    .padding-#{$side}-#{$space} { // ex. padding-top-16
      padding-#{$side}: #{$space}px !important;
    }
  }
}

/**
BORDER RADII
 */
$borderRadii: (8); // Adjust this to include the pixel amounts you need.
@each $radius in $borderRadii {
  .rounded-#{$radius} { // ex. rounded-8
    border-radius: #{$radius}px !important;
  }
}

/**
LINE HEIGHT
 */
$lineheights: (16, 20); // Adjust this to include the pixel amounts you need.
@each $lineheight in $lineheights {
  .lh#{$lineheight} { // ex. lh16
    line-height: #{$lineheight}px !important;
  }
}

/**
CURSOR
 */
.cursor-pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

/**
OVERFLOW
 */
.overflow-hidden {
  overflow: hidden;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-auto {
  overflow: auto;
}

.overflow-y-auto {
  overflow-y: auto;
  overflow-x: hidden;
}

.overflow-x-auto {
  overflow-x: auto;
  overflow-y: hidden;
}

/**
FLEX
 */
.flex-one, .wrapper {
  flex: 1;
}

.flex-100-y-auto {
  flex: 1;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
