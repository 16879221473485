@import "../../../node_modules/bootstrap/scss/functions";

// Style Guide Colors
// https://tangocard.invisionapp.com/d/main#/console/13557163/288673899/preview
$white:                  #FFFFFF;
$white-medium:           #FDFDFD;
$opaque-white-medium:    rgba($white, 0.70);
$white-dark:             #F7F7F7;
$white-darker:           #F3F3F3;
$white-darkest:          #DDDDDD;

$gray-100:               #F5F5F5;
$gray-200:               #eee;
$gray-300:               #ddd;
$gray-500:               #aaa;
$gray-700:               #555;
$gray-800:               #222;
$gray-900:               #111;

$black:                  #000;

$gray-lighter:           $gray-100;
$gray-subtle:            $gray-200;
$gray-light:             $gray-500;
$gray:                   $gray-700;
$gray-dark:              $gray-800;
$gray-darker:            $gray-900;
$gray-warm:              #5B5A5B;
$gray-warmer:            #516371;
$gray-cool:              #A39B88;

$blue-gray-light:        #E8EBEC;
$blue-gray-medium:       #BDC3C7;
$blue-gray:              #868C97;
$blue-gray-dark:         #52616D;
$blue-gray-darker:       #42505B;

$blue-dark:              #1089A0;
$blue-medium:            #29A2B9;
$blue-light:             #43BCD3;
$blue-light1:            #73B8C5;
$blue-light2:            #2DB6D125;

$dark-blue:              #22292F;

$orange-medium:          #F16521;
$orange-dark1:           #F15A29;
$orange-dark2:           #D95414;

$green-dark:             #678E72;
$green-medium:           #73A127;
$green-light:            #94C345;

$red-dark2:              #BE3b4A;
$red-dark1:              #D4191E;
$red-medium:             #EC2227;
$red-light:              #F37D8D;

$yellow:                 #FFE912;
$yellow-dark:            #F5B21E;

// Bootstrap Naming Convention Mappings
//
$primary-light:          $blue-light;
$primary-light1:         $blue-light1;
$primary:                $blue-medium;
$primary-dark:           $blue-dark;
$secondary-light:        $white-dark;
$secondary:              $gray-100;
$success-light:          $green-light;
$success:                $green-medium;
$success-dark:           $green-dark;
$info-light:             $blue-gray-light;
$info-medium:            $blue-gray-medium;
$info:                   $blue-gray;
$info-dark:              $blue-gray-dark;
$dark:                   $dark-blue;
$warning:                $yellow;
$warning-dark:           $yellow-dark;
$danger-light:           $red-light;
$danger:                 $red-medium;
$danger-darker:          $red-dark2;
$accent:                 $orange-medium;

$brand-opacity:          .6;
$opaque-primary:         rgba($primary, $brand-opacity);
$opaque-success:         rgba($success, $brand-opacity);
$opaque-info:            rgba($info-dark, $brand-opacity);
$opaque-warning:         rgba($warning, $brand-opacity);
$opaque-danger:          rgba($danger, $brand-opacity);
$opaque-accent:          rgba($accent, $brand-opacity);

// Brand Scale
$theme-colors: (
  primary: $primary,
  secondary: $secondary,
  success: $success,
  info: $info-dark,
  warning: $warning,
  danger: $danger,
  accent: $accent,
  dark: $dark
);

// BOOTSTRAP OVERRIDES:
//

// Colors
//
$body-bg:                $white;
$body-color:             $dark;
$border-color:           $gray-subtle;

$body-padding-top:       30px;

// Options
//
$border-radius:             2px;

// Layout
//
$grid-gutter-width:         20px;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:           0;
$btn-border-radius-lg:        0;
$btn-border-radius-sm:        0;

// icons
$icon-font-path: "./assets/fonts/";
$icon-font-name: "toolkit-entypo";

// Spacing
//
$spacer:            20px;

// Typography
//
$secondary-text-color:        $blue-gray-darker;

// Fonts
$font-family-sans-serif:      "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
$font-family-base:            $font-family-sans-serif;

// Body
$font-size-base:              0.875rem;
$font-weight-normal:          300;
$text-color:                  $body-color;
$font-weight-bold:            500;

// Headings
$display1-size:               2.25rem;
$display2-size:               1.75rem;
$display3-size:               1.5rem;
$display4-size:               1.25rem;

$headings-font-weight:        $font-weight-normal;
$h2-font-size:                $display3-size;

$display1-weight:             500;
$display2-weight:             $font-weight-normal;
$display3-weight:             $font-weight-normal;
$display4-weight:             $font-weight-normal;

$lead-font-size:              1.125rem;
$lead-font-weight:            500;

$text-muted:                  #999;
$hr-border:                   $gray-subtle;

// cards
$card-bg: rgba(255, 255, 255, 0.07);

// Forms
//
$input-height-inner:            48px;
$input-line-height:             $input-height-inner;
$input-padding-x:               8px;
$input-color:                   $gray-darker;
$input-border-color:            $gray-300;
$input-focus-box-shadow:        none;
$input-disabled-bg:             $gray-lighter;
$custom-select-disabled-bg:     $input-disabled-bg;
$input-placeholder-color:       $gray-light;

$input-height-sm:               42px;
$input-padding-x-sm:            $input-padding-x;
$input-line-height-sm:          $input-height-sm;
$input-border-radius-sm:        $border-radius;

$input-height-xs:               26px;
$input-padding-x-xs:            .25rem;
$input-padding-y-xs:            .25rem;
$input-line-height-xs:          $font-size-base;
$input-border-radius-xs:        0;


// Table
//
$table-border-color:            $gray-100;

// Buttons
//
$btn-font-weight:                normal;
$input-btn-padding-y:            0;
$input-btn-padding-x:            16px;
$input-btn-font-size:            $font-size-base;
$input-btn-line-height:          3rem;

$input-btn-padding-y-sm:         0;
$input-btn-padding-x-sm:         1rem;
$input-btn-font-size-sm:         $font-size-base;
$input-btn-line-height-sm:       40px;

//Alerts
//
$alert-bg-level:                    -6;
$alert-border-level:                -4;
$alert-color-level:                 10;

// modals
$zindex-modal-backdrop:         10001;
$zindex-modal:                  10002;
$modal-content-border-radius:       0;

// List Group
//
$list-group-bg: transparent;

// Custom Control
//
$custom-control-indicator-box-shadow: rgba(0,0,0,.15);

// Container
$container-max-widths: (
  md: 880px,
  lg: 950px,
  xl: 1100px
);
@include _assert-ascending($container-max-widths, "$container-max-widths");

// VARS FOR CUSTOM ELEMENTS:
//
$zindex-account-switcher:    9000;

// Icon nav
//
$zindex-iconav:              9001;
$iconav-width-closed:        62px;
$iconav-width-open:          220px;
$iconav-bg:                  #f2f2f2;
$iconav-border:              #fff;
$iconav-width:               62px;
$iconav-brand-color:         #495057;
$iconav-brand-hover-color:   inherit;
$iconav-brand-bg:            theme-color("primary");
$iconav-brand-border:        $iconav-border;
$iconav-link-color:          inherit;
$iconav-link-border:         $iconav-border;
$iconav-link-active-color:   $gray-700;
$iconav-link-active-bg:      $iconav-border;

// Dashhead
//
$dashhead-toolbar-divider-bg: $gray-200;
$dashhead-subtitle-color: lighten($gray-700, 35%);

// Tooltips
//
$zindex-tooltip: 9003;

// Toast
//
$zindex-toast: 9004;

// Navbar
//
$navbar-height:             70px;
$zindex-navbar:             9000;

// Page Header
//
$zindex-page-header:        8000;
