html, body {
  min-height: 100%;
  height: 100%;
  overflow: hidden !important;
  margin: 0;
}

body {
  min-height: 100%;
}

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: $black;
  padding: 0.2em 0;
}

.col-small-gutters {
  padding-right: 5px;
  padding-left: 5px;
}

.container.container-md {
  max-width: 900px;
}

.col-no-gutters {
  padding-right: 0;
  padding-left: 0;
}

.pull-left {
    float: left;
}

.pull-right {
    float: right;
}
