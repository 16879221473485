
.select-payment-method-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.empty-state {
  background-color: var(--t-color-background-raised);
  border-radius: var(--t-border-size-4);
  color: var(--t-color-text-weak);
  padding: 2rem;
  text-align: center;
}
