

.account-group-container {
  border-radius: var(--t-radius-3);
  border: var(--t-border-size-1) solid var(--t-color-border);
}

.group-title {
  font-weight: var(--t-font-weight-5);
  font-size: var(--t-font-size-0);
  color: #22292F;
}
