.froala-component {

  &.editor {
     border: solid $input-border-width $input-border-color;
     color: $input-color;
     font-weight: normal;
     padding: $input-padding-y $input-padding-x;
     border-radius: $input-border-radius;
     min-height: 150px;
   }

}

