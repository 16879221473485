/**
FONT FACES
 */
.font-gotham-md {
  @include gotham-medium;
}

.font-gotham-book {
  @include gotham-book;
}

/**
HEADINGS
 */
h2, .h2 {
  font-weight: $font-weight-bold;
}

/**
DISPLAY HEADINGS
 */
.display-1 {
  @include gotham-book;
  line-height: 2.5rem;
}
.display-2 {
  @include gotham-book;
  line-height: 2.125rem;
}
.display-3 {
  @include gotham-book;
  line-height: 2.25rem;
}
.display-4 {
  @include gotham-book;
  line-height: 1.75rem;
}

/**
LEAD
 */
.lead {
  @include gotham-medium;
}

/**
DISCLAIMER
 */
.disclaimer {
  font-size: 10px;
}

/**
LINE
 */
.line-1 {
  @include gotham-book;
  font-size: 1rem;
  line-height: 1.375rem;
}
.line-2 {
  @include gotham-book;
  font-size: $font-size-base;
  line-height: 1.25rem;
}
.line-3 {
  @include gotham-book;
  font-size: 0.75rem;
  line-height: 1.125rem;
}

/**
ANCHOR TAGS
 */
a:hover, .hand {
  cursor: pointer;
  text-decoration: none;
}

.no-link-color {
  color: inherit;
}

/**
TOOLTIP
 */
.tooltip {
  @include gotham-book;
}

/**
FONT SIZES
 */
$fontsizes: (10, 12, 13, 14, 16, 18, 26, 32); // Adjust this to include the pixel amounts you need.
@each $fontsize in $fontsizes {
  .font-size-#{$fontsize}px {
    font-size: #{$fontsize}px;
  }
}

/**
TEXT COLORS
 */
.text-gray-dark {
  color: $gray-dark;
}

.text-gray-darker {
  color: $gray-darker;
}

.text-gray-light {
  color: $gray-light;
}

.text-blue-gray {
  color: $blue-gray;
}

.text-info-medium {
  color: $info-medium;
}

.text-opaque-white-medium {
  color: $opaque-white-medium
}

.color-secondary-text {
  color: $info;
}
.color-tertiary-text {
  color: $info-medium;
}

/**
MICRO COPY
 */
.micro-copy {
  margin-top: 16px;
  font-size: 12px;
  color: $gray-light;
}

/**
OPTION LABEL
 */
.option-label {
  line-height: 20px;
  font-size: 14px;
  color: $primary-light;
  margin-bottom: 16px;
  font-weight: bold;
}
