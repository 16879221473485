
@import "~@/assets/scss/_mixins.scss";
@import "~@/components/shared/lists/FundingMethod.scss";

.funding-method-option {
  display: flex;
  align-items: flex-start;

  &.dropdown {
    margin: 6px 0;
  }
}

.display-name {
  color: var(--t-color-text);
  font-family: $gotham-font-family;
  font-size: var(--t-font-size-0);
  font-weight: var(--t-font-weight-4);
  line-height: $funding-method-image-height;

  &.disabled {
    color: var(--t-color-text-weakest);
  }
}

.helper-text {
  color: var(--t-color-text-weak);
  font-family: $gotham-font-family;
  font-size: 0.75rem;
  font-weight: var(--t-font-weight-4);
}

.account-button{
  font-weight: var(--t-font-weight-6);
  color: var(--t-color-primary);
  font-size: 0.75rem;
}
