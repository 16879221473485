// Dashhead
//
// Custom header for the top of dashboard pages.

.dashhead {
  @include clearfix;
}

.dashhead-titles {
  @include media-breakpoint-up(md) {
    float: left;
  }
}

.dashhead-title {
  margin-top: 0;
}

.dashhead-subtitle {
  margin-top: 0;
  margin-bottom: ($spacer * .25);
  font-weight: normal;
  font-size: 85%;
  color: $dashhead-subtitle-color;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.dashhead-toolbar {
  margin-left: 0;

  .input-with-icon {
    width: 100%;
    margin-bottom: 10px;
    margin-left: 0;
  }

  @include media-breakpoint-up(md) {
    float: right;
    margin-top: 12px;

    .input-with-icon {
      width: 180px;
      margin-bottom: 0;
    }
  }
}

.dashhead-toolbar-divider {
  float: left;
  width: 1px;
  height: 34px;
  margin-left: 10px;
  margin-right: 10px;
  background-color: $dashhead-toolbar-divider-bg; // #eee;
}

.dashhead-toolbar-item dashhead-toolbar-i{
  float: left;

  @include media-breakpoint-down(sm) {
    &.btn-group-halves {
      width: 100%;

      .btn {
        width: 50%;
        margin-left: 0;
      }
    }

    &.btn-group-thirds {
      width: 100%;

      .btn {
        width: 33%;
        margin-left: 0;

        &:last-child {
          width: 34%;
        }
      }
    }

    &.btn-group-fourths {
      width: 100%;

      .btn {
        width: 25%;
        margin-left: 0;
      }
    }
  }
}
