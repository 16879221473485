
@import '~@/assets/scss/theme';

.totals-text {
  color: var(--t-color-text);
}
.pending-message{
  background-color:#E4E3EF;
  border-radius:0.5rem;
  margin-top: -0.5rem;
  margin-bottom: 1.5rem;
  .v-icon {
    color:var(--t-color-text);
  }
}
