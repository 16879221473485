.loader {
  &.fixed-loader {
    top: 40%;
    left: 30%;
    width: 200px;
    z-index: 10000;
    margin: 0 auto;
    position: absolute;
  }
  text-align: center;
  background-color: transparent;
  .loadingText {
    font-weight: 700;
    margin-top: 20px;
  }

  .spinningCircle {
    margin:0 auto;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    border: 5px solid rgba(255, 255, 255, 0);
    border-top-color: #7fc4d1;
    border-right-color: #7fc4d1;
    -webkit-animation: single2 2s infinite linear;
    animation: single2 2s infinite linear;
  }

  @-webkit-keyframes single2 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      border-top-color: $primary;
      border-right-color: $primary;
    }
    50% {
      border-top-color: $primary;
      border-right-color: $primary;
    }
    100% {
      -webkit-transform: rotate(720deg);
      transform: rotate(720deg);
      border-top-color: $primary;
      border-right-color: $primary;
    }
  }

  @keyframes single2 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      border-top-color: $primary;
      border-right-color: $primary;
    }
    50% {
      border-top-color: $primary;
      border-right-color: $primary;
    }
    100% {
      -webkit-transform: rotate(720deg);
      transform: rotate(720deg);
      border-top-color: $primary;
      border-right-color: $primary;
    }
  }
}

.inputLoader {
  padding: 4px 4px 4px 0px;
  display: inline-block;
  margin-top: -8px;

  .spinningCircle {
    height: 26px;
    width: 26px;
    border-radius: 50%;
    border: 5px solid rgba(255, 255, 255, 0);
    border-top-color: #7fc4d1;
    border-right-color: #7fc4d1;
    -webkit-animation: single2 2s infinite linear;
    animation: single2 2s infinite linear;
  }
}
