.dropdown-menu-cover {

  .dropdown-menu {
    margin-top: -$input-height-inner - 2 !important;
    box-shadow: 0 1px 1px rgba($black, 0.3);
    border-color: $gray-lighter;
    border-radius: 0;
  }

  .btn-sm + .dropdown-menu {
    margin-top: -$input-height-sm - 2 !important;
  }

  .btn-xs + .dropdown-menu {
    margin-top: -$input-height-xs - 2 !important;
  }

}

.dropdown-menu {
  max-height: 50vh;
  overflow: auto;

  .dropdown-item {
    &, &:active, &:focus, &:hover {
      outline: none !important;
    }
  }
}
