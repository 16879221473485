
@import "~@/assets/scss/variables";
@import "~@/assets/scss/mixins";

#account-search {
  ::v-deep .search {
    margin-bottom: 5px;
    border: none;
  }
}

.account-card {
  padding: .75rem;
  background-color: var(--t-color-surface);
  margin-bottom: 4px;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.20);

  .account-card-information {
    width: 65%;

    > p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: max-content;
      max-width: 100%;

      &:last-child {
        margin: 0;
      }
    }
  }

  .account-card-available-balance-disclaimer {
    font-size: 10px;
  }
}

  @include progress-circle($primary, $gray-light, $primary);

  .info-text {
    color: var(--t-color-weak);
    font-size: 24px;
    padding-top: 32px;
  }

.account-switcher-selected-icon {
  width: 30px;
  height: 30px;
  background: no-repeat center url("../../../assets/images/account-switcher-check-mark.svg");
  background-size: 100%;
  display: inline-block;
}

.account-switcher-overflow-properties {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 62vh;
}

#account-switcher {
  position: fixed;
  right: 0;
  top: 70px; // height of the top nav bar
  background-color: var(--t-color-surface);
  height: calc(100% - 70px);
  z-index: $zindex-account-switcher;
  border-radius: 8px;
}
