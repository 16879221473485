// mixins file. If it starts getting unwieldy, we can break it up into modules

// Compass utilities
@import "../../../node_modules/compass-sass-mixins/lib/compass";
@import "../../../node_modules/bootstrap/scss/mixins";
//new nav mixins
@mixin nav-icon-open-close($icon-url) {
  display: inline-block;
  height: 70px;
  width: 70px;
  background: no-repeat center url($icon-url)
}

@mixin control-icon($active-icon, $inactive-icon) {
  span.control-icon-active {
    background: center no-repeat url($active-icon);
  }
  span.control-icon-inactive {
    background: center no-repeat url($inactive-icon);
  }
}

//end new nav mixins

//nav bar icons/background mixin
@mixin nav-icon($default-icon, $active-icon) {
  span {
    background: no-repeat center url($default-icon);
  }
  &.router-link-active {
    background-color: #fafafa;
    span {
      background: no-repeat center url($active-icon);
    }
  }
}

@mixin nav-tooltip($module-name, $offset-left) {
  #container-nav-tooltip-#{$module-name} {
    .tooltip {
      left: $offset-left !important;
    }
  }
}

@mixin number-circle($background-color) {
  .number-circle {
    display: flex;
    border-radius: 50%;
    min-width: 30px;
    min-height: 30px;
    padding: 1px;
    margin-right: 16px;
    color: white;
    box-sizing: initial;
    font: 16px Medium, sans-serif;
    background: $background-color;
  }
}

@mixin selected-check-icon($icon-color, $background-color, $icon-size) {
  .selected-check-icon {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    border-radius: 50%;
    background: $background-color;
    width: $icon-size;
    height: $icon-size;
    padding-left: 2px;
  }
  .icon:before, .tci:before {
    color: $icon-color;
    font-size: ($icon-size)-5;
  }
}

// styles the icons for the order details line item table email status
@mixin order-details-icon($icon) {
  .email-status-icon {
    background: no-repeat center url($icon);
  }
}

@mixin order-details-status-icon($width, $height, $url, $color) {
  width: $width;
  height: $height;
  content: url($url);
  color: $color;
}

/*
  This mixin creates a circle to show the progress by percentage.
  Example use:
    <div class="circle big center" :class="['circle-pct-' + pct]">
      <span>{{pct}}%</span>
      <div class="slice">
        <div class="bar"></div>
        <div class="fill"></div>
      </div>
    </div>

  In scss:
    @include progress-circle($primary, $gray-light, $primary);
 */
@mixin progress-circle($primary-color, $secondary-color, $text-color) {

  // VARS
  $circle-width: 0.08em;
  $circle-width-hover: 0.04em;

  // CIRCLE
  // classes 2 extend
  .rect-auto {
    clip: rect(auto, auto, auto, auto);
  }

  .pie {
    position: absolute;
    border: $circle-width solid $primary-color;
    width: 1 - (2 * $circle-width);
    height: 1 - (2 * $circle-width);
    clip: rect(0em, 0.5em, 1em, 0em);
    border-radius: 50%;
    @include rotate(0deg);
  }

  .pie-fill {
    @include rotate(180deg);
  }

  // main
  .circle {
    *, *:before, *:after {
      @include box-sizing(content-box);
    }

    position: relative;
    font-size: 120px;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    float: left;
    margin: 0 0.1em 0.1em 0;
    background-color: $secondary-color;

    // center circle to its parent
    &.center{
      float: none;
      margin: 0 auto;
    }

    // bigger size
    &.big{
      font-size: 240px;
    }

    // smaller size
    &.small{
      font-size: 80px;
    }

    // centered value inside circle
    > span {
      position: absolute;
      z-index: 1;
      left: 0;
      top: 0;
      width: 5em;
      line-height: 5em;
      font-size: 0.2em;
      color: $text-color;
      display: block;
      text-align: center;
      white-space: nowrap;
      @include transition-property(all);
      @include transition-duration(0.2s);
      @include transition-timing-function(ease-out);
    }

    // centered value inside circle
    > p {
      position: absolute;
      z-index: 1;
      left: 0;
      top: 0;
      width: 5em;
      line-height: 5em;
      font-size: 0.2em;
      color: $text-color;
      display: block;
      text-align: center;
      white-space: nowrap;
      @include transition-property(all);
      @include transition-duration(0.2s);
      @include transition-timing-function(ease-out);
    }

    // background inside the circle
    &:after{
      position: absolute;
      top: $circle-width;
      left: $circle-width;
      display: block;
      content: " ";
      border-radius: 50%;
      background-color: $white;
      width: 1 - (2 * $circle-width);
      height: 1 - (2 * $circle-width);
      @include transition-property(all);
      @include transition-duration(0.2s);
      @include transition-timing-function(ease-in);

    }

    // the slice (mask)
    .slice {
      position: absolute;
      width: 1em;
      height: 1em;
      clip: rect(0em, 1em, 1em, 0.5em);
    }

    // circle to show the status
    .bar {
      @extend .pie;
    }

    // loop to create all needed elements automatically
    @for $j from 51 through 100 {
      &.circle-pct-#{$j} .slice {
        @extend .rect-auto;
      }

      &.circle-pct-#{$j} .bar:after{
        @extend .pie-fill;
      }

      &.circle-pct-#{$j} .fill{
        @extend .pie;
        @extend .pie-fill;
      }
    }

    // loop to rotate all 100 circles
    @for $j from 1 through 100 {
      &.circle-pct-#{$j} .bar {
        @include rotate((360/100*$j) + deg);
      }
    }
  }
}

/*
  These mixins creates a pie-chart to show the progress by percentage.

  Example use:
    <div class="pie-wrapper pie-wrapper--solid" :class="['pie-pct-' + pct]">
      <span>{{text}}</span>
    </div>

  In scss:
    @include progress-pie($blue-light1, $white, $primary);
 */
@mixin size($w, $h){
  height: $h;
  width: $w;
}

@mixin draw-progress($progress) {
  @if ($progress <= 50) {
    .pie {
      .right-side {
        display: none;
      }
    }
  }
  @else {
    .pie {
      clip: rect(auto, auto, auto, auto);

      .right-side {
        transform: rotate(180deg);
      }
    }
  }
  .pie {

    .left-side {
      $rotate: $progress * 3.6;
      transform: rotate($rotate'deg');
    }
  }
}

@mixin draw-progress--solid($progress, $color, $bg) {
  &:before {
    @if ($progress <= 50) {
      background: $bg;
      transform: rotate((100 - (50 - $progress)) / 100 * 360deg * -1);
    }
    @else {
      background: $color;
      transform: rotate((100 - $progress) / 100 * 360deg);
    }
  }
  background: linear-gradient(to right, $color 50%, $bg 50%);
}
@mixin progress-pie($primary-color, $background-color, $text-color) {
  $size: 1em;


  .pie-wrapper {
    @include size($size, $size);
    float: left;
    margin: 15px;
    position: relative;

    &:nth-child(3n+1) {
      clear: both;
    }

    > span {
      $font-size: $size / 4;
      $font-size-redo: $size * 4;

      background: none;
      bottom: $font-size-redo / 10;
      color: $text-color;
      cursor: default;
      display: block;
      font-size: 60px;
      left: $font-size-redo / 10;
      line-height: $size * 1.5;
      float: none;
      margin: 0 auto;
      position: absolute;
      right: $font-size-redo / 10;
      text-align: center;
      top: $font-size-redo / 10;

      @include gotham-medium;
    }

    @for $j from 1 through 100 {
      &.progress-#{$j} {
        @include draw-progress($j)
      }
    }
  }

  .pie-wrapper--solid {
    float: none;
    margin: 0 auto;
    border-radius: 50%;
    overflow: hidden;

    &:before {
      border-radius: 0 100% 100% 0 / 50%;
      content: '';
      display: block;
      height: 100%;
      margin-left: 50%;
      transform-origin: left;
    }

    .label {
      background: transparent;
    }

    @for $j from 1 through 100 {
      &.pie-pct-#{$j} {
        @include draw-progress--solid($j, $primary-color, $background-color)
      }
    }
  }
}


/* ==========================================================================
Fonts
========================================================================== */
// needed to override Vuetify !important
$gotham-font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;

// Gotham Thin
@mixin gotham-thin {
  font-family: $gotham-font-family;
  font-style: normal;
  font-weight: 100;
}

// Gotham Thin Italic
@mixin gotham-thin-italic {
  font-family: $gotham-font-family;
  font-style: italic;
  font-weight: 100;
}

// Gotham Extra Light
@mixin gotham-extra-light {
  font-family: $gotham-font-family;
  font-style: normal;
  font-weight: 200;
}

// Gotham Extra Light Italic
@mixin gotham-extra-light-italic {
  font-family: $gotham-font-family;
  font-style: italic;
  font-weight: 200;
}

// Gotham Light
@mixin gotham-light {
  font-family: $gotham-font-family;
  font-style: normal;
  font-weight: 300;
}

// Gotham Light Italic
@mixin gotham-light-italic {
  font-family: $gotham-font-family;
  font-style: italic;
  font-weight: 300;
}

// Gotham Book
@mixin gotham-book {
  font-family: $gotham-font-family;
  font-style: normal;
  font-weight: normal;
}

// Gotham Book Italic
@mixin gotham-book-italic {
  font-family: $gotham-font-family;
  font-style: italic;
  font-weight: 400;
}

// Gotham Medium
@mixin gotham-medium {
  font-family: $gotham-font-family;
  font-style: normal;
  font-weight: 500;
}

// Gotham Medium Italic
@mixin gotham-medium-italic {
  font-family: $gotham-font-family;
  font-style: italic;
  font-weight: 500;
}

// Gotham Bold
@mixin gotham-bold {
  font-family: $gotham-font-family;
  font-style: normal;
  font-weight: 700;
}

// Gotham Bold Italic
@mixin gotham-bold-italic {
  font-family: $gotham-font-family;
  font-style: italic;
  font-weight: 700;
}

// Gotham Black
@mixin gotham-black {
  font-family: $gotham-font-family;
  font-style: normal;
  font-weight: 800;
}

// Gotham Black Italic
@mixin gotham-black-italic {
  font-family: $gotham-font-family;
  font-style: italic;
  font-weight: 800;
}

// Gotham Ultra
@mixin gotham-ultra {
  font-family: $gotham-font-family;
  font-style: normal;
  font-weight: 900;
}

// Gotham Ultra Italic
@mixin gotham-ultra-italic {
  font-family: $gotham-font-family;
  font-style: italic;
  font-weight: 900;
}
