.t-card {
  padding: 2rem;
  background-color: white;
  border: 1px solid var(--t-color-border);
  border-radius: .6rem;
}

.t-card-raised {
  padding: 2rem;
  background-color: var(--t-color-surface-raised);
  border-radius: .6rem;
}

.t-card-heading {
  font-size: clamp(.875rem, 2vw, 1.125rem);
  font-weight: 500;
}

.card-information {
  flex: 1 1 auto;
  background: #fff;
  border-radius: 0; // consider overriding in bootstrap
  border: 0;
  padding: 32px;
  overflow: auto;
  margin-bottom: 5px;
}

.card-sm {
  width: 30%;
}

.card-lg {
  width: 70%;
}

.card-right {
  margin-right: 5px;
}

.card-body-row {
  margin: 0 0;
  padding: 15px 0;
}

.card-body {
  flex-direction: column;
  flex: 1 1 auto;
  padding: 0;
}
