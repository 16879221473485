
@import "~@/assets/scss/mixins";
@import "~@/assets/scss/variables";

#top-bar-main-container {
  width: 100%;
}

#nav-bar {
  color: var(--t-color-surface);
  height: $navbar-height;
  width: 100%;
  background-color: var(--t-color-navigation-surface-inverted);
  position: fixed;
  z-index: 9000;
  top: -70px;
}

#hover-bar {
  height: 10px;
  background-color: transparent;
  width: 100%;
  position: fixed;
  z-index: 9000;
}

#open-close {
  background: var(--t-color-navigation-surface-inverted);
  padding: 0;
  border-width: 0;
  &:focus, &:active, &:hover {
    box-shadow: none;
  }
}

#open {
  @include nav-icon-open-close('../../../assets/images/nav/new/hamburger_menu.svg');
}

#platform-name {
  align-content: center;
  display: flex;

  > p {
    color: var(--t-color-text-inverted);
    margin: 0;
    margin-left: 32px;
    line-height: $navbar-height;
    font-size: $font-size-base;
    display: none;
    @include media-breakpoint-up(sm) {
      max-width: 200px;
      display: flex;
      align-self: center;
      line-height: 100%;

    }
    @include media-breakpoint-up(md) {
      font-size: var(--t-font-size-2);
      font-weight: var(--t-font-weight-5);
      max-width: 400px;
    }
  }
}

.transition {
  transition: top 1s ease 0s;
}

.beamer-icon {
  height: 30px;
  width: 30px;
  display: inline-block;
}

#beamer {
  @include nav-icon('../../../assets/images/megaphone-outline.svg', '../../../assets/images/megaphone-outline.svg');
}

#beamer-container {
  height: 100%;
  width: 70px;
}

.account-menu {
  border-radius: .5rem;
  height: 2.5rem;
  width: 315px;
  margin-inline-end: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: var(--t-color-overlay);

  #account-information-menu {
    background-color: var(--t-color-surface);
    color: var(--t-color-text);
    width: 315px;
    right: 0;
    top:3rem;
    position: absolute;
    padding: .75rem;
    box-shadow: 0 4px 6px 0 rgba(0,0,0,0.1);
    border-radius: .5rem;
    z-index: 9003;

    #menu-information {
      span {
        display: inline-block;
      }

      .label-title {
        color: var(--t-color-text);
      }

      .label-value {
        color: var(--t-color-text);
      }
    }
  }
}

.icon {
  color: var(--t-color-icon-inverted);
}
.quick-animation {
  animation-duration: 400ms;
}

.navigate-button {
  background-color: var(--t-color-surface);
  width: 100%;
  padding: .5rem;
  color: var(--t-color-text);
  cursor: pointer;
}

.pointer-down {
  margin-left: 4px;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 8px solid var(--t-color-surface);
}

#create-account-icon {
  background: center no-repeat url('../../../assets/images/add-circle.svg');
  height: 20px;
  width: 20px;
  margin-right: 8px;
  filter: brightness(0) invert(1);
}
.menu-information {
  background-color: var(--t-color-surface);
  margin-bottom: 1rem;
  padding: .5rem;
  border-radius: .25rem;
}
#account-switcher-close-icon {
  font-size: 28px;
}
.menu-information-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.menu-information-row-label {
  flex: 0 0 auto;
  font-weight: 500;
  margin-right: auto;
}

.menu-information-row-value{
  font-size: 14px;
  word-wrap: break-word;
  width: 175px;
  text-align: end;
}

@media (max-width: $breakpoint-md) {
  .slider-header {
    width: 100%;
    position: absolute !important;
  }
}
