:where(:not(:active):focus-visible) {
  outline-offset: 5px;
}

:where(body) { 
  min-block-size: 100%;
}

:where(a, a.v-btn) {
  &:where([href]) {
    text-decoration-color: var(--t-color-text-link);

    &:where(:visited) {
      text-decoration-color: var(--t-color-text-link-visited);
    }
  }

  &:where(:not(:hover)) {
    text-decoration: inherit;
  }
}

h1, h2, h3, h4, h5, p, label {
  color: var(--t-color-text);
}

::placeholder {
  color: var(--t-color-text-weak);
  opacity: .75;
}
