
@import "~@/assets/scss/_mixins.scss";

::v-deep .v-select__selections {
  font-family: $gotham-font-family;
}

// "use-vuetify" on the address-autocomplete doesn't work correctly through mango so we need to manually restyle the
// address-autocomplete to look like a vuetify component
.billing-address {
  border: 1px solid var(--t-color-border) !important;
  border-radius: 4px !important;
  height: 40px;

  &:hover {
    border: 1px solid var(--t-color-text) !important;
  }
  &:focus {
    border: 2px solid var(--t-color-primary) !important;
  }
}

.dialog-link {
  font-size: .875rem;
  font-weight: 500;
  color: var(--t-color-text-link);

  &:hover {
    text-decoration: underline !important;
  }
}
