html {
  --t-border-size-1: 1px;
  --t-border-size-2: 2px;
  --t-border-size-3: 5px;
  --t-border-size-4: 10px;
  --t-border-size-5: 25px;
  --t-radius-0: .25rem;
  --t-radius-1: .5rem;
  --t-radius-2: 0.875rem;
  --t-radius-3: 1rem;
  --t-radius-4: 2rem;
  --t-radius-5: 4rem;
  --t-radius-6: 8rem;
  --t-radius-round: 1e5px;
}
