
@import "~@/assets/scss/modules/_borders.scss";
@import "~@/components/shared/tc-dialog/TcDialog.scss";

/* v-dialog styling requires global scope since the dialog attaches to the root element */
.v-dialog.tc-dialog {
  border-radius: var(--t-radius-3);
  max-height: calc(100% - 16vh - (#{$tc-dialog-vertical-padding} * 2)) !important;
  position: absolute;
  top: 8vh;

  &.v-dialog--fullscreen {
    border-radius: 0;
    max-height: 100% !important;
    top: 0;
  }
}
