@import "../variables";

.alerts {
  position: absolute;
  z-index: 10000;
  .alert {
    width: 100%;
    text-overflow: ellipsis;
    span {
      background: none;
      border: none;
      font: inherit;
      color: inherit;
      padding: 0;
      margin: 0;
    }
    .popover pre {
      font-size: 10px;
    }
  }
  .toast {
    background-color: #fff;
    position: fixed;
    width: 100%;
    &.left {
      left: 5px;
    }
    &.right {
      right: 5px;
    }
    &.top {
      top: 55px;
    }
    &.bottom {
      bottom: 55px;
    }
  }
}

@media screen and (min-width: $breakpoint-sm) {
  .alerts .toast {
    width: 50%;
  }
}

.alert {
  padding-bottom: 20px;
  padding-top: 20px;
}
