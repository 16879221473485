@import "./theme";
@import "../../../node_modules/bootstrap/scss/bootstrap";
@import "../../../node_modules/bootstrap-vue/dist/bootstrap-vue";
@import '../../../node_modules/froala-editor/css/froala_editor.pkgd.min';
@import "../../../node_modules/placeholder-loading/src/scss/placeholder-loading";
@import '../../components/shared/froala-editor/tango-custom-theme';
@import "~@tangocard/swag/dist/tc-icons/tc-icons.css";
@import './mixins';
@import './normalize';
@import "./modules/all";
@import "./partials/all";

#app .t-page-header-navigation {
  margin-block-start: 4rem;
}
