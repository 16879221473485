
.account-switcher {
  border: 1px solid var(--t-color-border);
  border-radius: 12px !important;
  margin-top: 4px;
  margin-bottom: 8px !important;
  transition: 300ms;

  .v-list-item__icon:first-child {
    margin-right: 14px;
  }

  img {
    width: 22px;
  }
}

.titles {
  display: none;
}

.controls-open {
  .account-switcher {
    margin-top: 0;
    margin-bottom: 4px !important;
    padding: 4px 11px !important; // override the super specific sidebar styles

    .titles {
      display: initial;
      width: calc(100% - 36px);
    }
  }
}
