// https://vuetifyjs.com/en/customization/presets/#preset-overrides-sass
// this !important trash is because we need to override the styles that Vuetify applies
// to the css with !important. This is why !important should never or very rarely used
@import './mixins';

@import '~@tangocard/swag/src/scss/vuetify/overrides';
$body-font-family: gotham-book;
$heading-font-family: gotham-book;
$heading-font-family: gotham-book;

.btn.btn-success,
.btn.btn-primary,
.btn.btn-info,
.btn.btn-danger {
    color: #ffffff;
}
.v-btn {
    text-transform: none !important;
}
div.v-application {
  ol,
  ul,
  .list-unstyled {
    padding: 0;
    margin: 0;
  }

  ul {
    padding-left: 0;
  }

  .display-1 {
    font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
    font-size: 2.25rem !important;
    line-height: 2.5rem !important;
  }
  .display-2 {
    font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
    font-size: 1.75rem !important;
    line-height: 2.125rem !important;
  }
  .display-3 {
    font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
    font-size: 1.5rem !important;
    line-height: 2.275rem !important;
  }
  .display-4 {
    font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
    font-size: 1.25rem !important;
    line-height: 1.75rem !important;
  }
  .line-1 {
    font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
    font-size: .875rem !important;
    line-height: 1.375rem !important;
  }
  .line-2 {
    font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
    font-size: .875rem !important;
    line-height: 1.125rem !important;
  }
  .line-3 {
    font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
    font-size: .75rem !important;
    line-height: 1rem !important;
  }

  .warning {
    background-color: transparent !important;
    border-color: transparent !important;
  }
  .v-label {
    color: var(--v-secondary-base);
    padding-left: 10px;
  }
  .v-text-field__prefix {
    color: var(--v-secondary-base);
  }
  .v-text-field--outlined {
    &.v-input--is-readonly {
      fieldset {
        color: transparent !important;
      }
      input {
        color: rgba(0, 0, 0, 0.87);
        font-weight: 400;
          opacity: 1;
      }
    }
  }

  .v-application--wrap {
    min-height: 100% !important;

    .info {
      background-color: transparent !important;
      border-color: transparent !important;
    }

    .card-information {
      .headers-row {
        flex: 0 0 auto;
      }
    }
  }

  .v-data-table.align-top {
    .v-data-table__wrapper > table > tbody {
      tr {
        td {
          vertical-align: top;
        }
      }

      tr:hover {
        background: transparent !important;
      }
    }
  }

  .v-footer.resend-acknowledgement {
    border-top: 1px solid #E0E0E0;

    .footer-wrapper > .v-card > .layout {
      display: flex;
      height: 60px;
      .v-btn {
        margin: 0 auto;
      }

      .v-input.acknowledgement-checkbox > .v-input__control {
        height: 100%;

        .v-messages {
          visibility: hidden;
        }

        .v-input__slot {
          margin: auto;
          width: max-content;

          .v-input--selection-controls__input {
            margin-bottom: 5px;
          }

          .v-label {
            margin-right: 8px;
          }
        }
      }
    }
  }

  .rounded-lg.v-btn {
    text-transform: none !important;
  }

  .rounded-lg.v-btn--outlined {
    border: thin solid var(--t-color-border);
  }

  .v-snack {
    z-index: 10000;
  }

  //  This app was built in vue 2.3 which didn't have top or bottom margin on the row. Updating past 2.4 the row now has margin, this changes it back.
  .row {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .v-chip{
    color:var(--t-color-text) !important;
    font-weight: 500 !important;
  }
}
