
.add-funds-button {
  min-width: 10px !important;
  max-width: 42px;
  border: var(--t-border-size-1) solid var(--t-color-border);
  border-radius: var(--t-radius-1);
  background-color: var(--t-color-background) !important;
  font-weight: var(--t-font-weight-5);
  font-size: var(--t-font-size-0);
  color: #22292F;
}

.active-account-check {
  color: var(--t-color-primary) !important;
}

.active-account  {
  border-radius: 8px;
  background-color: var(--t-color-primary-weakest);
  color: var(--t-color-primary) !important;
}

.selectable-container {
  cursor:pointer;
}

.account-name {
  font-weight: var(--t-font-weight-4);
  font-size: var(--t-font-size-0);
  color: #22292F;
}

.account-identifier {
  font-weight: var(--t-font-weight-4);
  font-size: 12px;
  color: #666666;
}

.account-balance {
  font-weight: var(--t-font-weight-4);
  font-size: var(--t-font-size-0);
  color: #22292F;
}
