:where(html) {
  --t-font-sans: system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif;
  --t-font-serif: ui-serif,serif;
  --t-font-mono: Dank Mono,Operator Mono,Inconsolata,Fira Mono,ui-monospace,SF Mono,Monaco,Droid Sans Mono,Source Code Pro,monospace;
  --t-font-weight-1: 100;
  --t-font-weight-2: 200;
  --t-font-weight-3: 300;
  --t-font-weight-4: 400;
  --t-font-weight-5: 500;
  --t-font-weight-6: 600;
  --t-font-weight-7: 700;
  --t-font-weight-8: 800;
  --t-font-weight-9: 900;
  --t-font-lineheight-00: .95;
  --t-font-lineheight-0: 1.1;
  --t-font-lineheight-1: 1.25;
  --t-font-lineheight-2: 1.375;
  --t-font-lineheight-3: 1.5;
  --t-font-lineheight-4: 1.75;
  --t-font-lineheight-5: 2;
  --t-font-lineheight-6: 2.25;
  --t-font-letterspacing-0: -.05em;
  --t-font-letterspacing-1: .025em;
  --t-font-letterspacing-2: .050em;
  --t-font-letterspacing-3: .075em;
  --t-font-letterspacing-4: .150em;
  --t-font-letterspacing-5: .500em;
  --t-font-letterspacing-6: .750em;
  --t-font-letterspacing-7: 1em;
  --t-font-size-00: .5rem;
  --t-font-size-0: .875rem;
  --t-font-size-1: 1rem;
  --t-font-size-2: 1.125rem;
  --t-font-size-3: 1.25rem;
  --t-font-size-4: 1.5rem;
  --t-font-size-5: 2rem;
  --t-font-size-6: 2.5rem;
  --t-font-size-7: 3rem;
  --t-font-size-8: 3.5rem;
  --t-font-size-fluid-0: clamp(.75rem, 2vw, 1rem);
  --t-font-size-fluid-1: clamp(1rem, 4vw, 1.5rem);
  --t-font-size-fluid-2: clamp(1.5rem, 6vw, 2.5rem);
  --t-font-size-fluid-3: clamp(2rem, 9vw, 3.5rem);
}
