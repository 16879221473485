

.form-fields *:last-child {
  margin-block-start: 1.5rem;
}
.v-expansion-panel-header {
 font-weight: 500;
 padding-left: 0;
 margin-left: -4px;
}
#add-card-nickname {
 font-weight: 500;
}
