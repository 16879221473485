html {
  --t-color-primary: hsl(246, 39%, 42%);
  --t-color-primary-weak: hsl(246, 39%, 42%, 50%);
  --t-color-primary-weakest: hsl(245, 27%, 91%);
  --t-color-text: hsl(0, 0%, 10%);
  --t-color-text-link: hsl(246, 39%, 42%);
  --t-color-text-link-blue: hsl(209.17, 100.0%, 57.25%);
  --t-color-text-link-visited: hsl(190, 82%, 15%);
  --t-color-text-link-inverted: hsl(0, 0%, 100%);
  --t-color-text-weak: hsl(0, 0%, 31%);
  --t-color-text-weakest: hsl(0, 0%, 66%);
  --t-color-text-strong: hsl(0, 0%, 0%);
  --t-color-text-inverted: hsl(0, 0%, 100%);
  --t-color-text-danger: hsl(357, 55%, 46%);
  --t-color-text-info: hsl(246, 39%, 42%);
  --t-color-navigation-surface: hsl(0, 0%, 100%);
  --t-color-surface-raised: hsl(0, 5%, 96%);
  --t-color-navigation-surface-inverted: hsl(0, 0%, 10%);
  --t-color-border: hsl(212, 11%, 72%);
  --t-color-border-weak: hsl(212, 11%, 72%, 50%);
  --t-color-surface: hsl(0, 0%, 100%);
  --t-color-surface-inverted: hsl(0, 0%, 35%);
  --t-color-background: hsl(0, 0%, 100%);
  --t-color-background-raised: hsl(0, 5%, 96%);
  --t-color-status-danger: hsl(357, 55%, 46%);
  --t-color-status-danger-weak: hsl(357, 73%, 56%);
  --t-color-status-danger-weakest: hsl(357,98%,96%);
  --t-color-status-info: hsl(246, 39%, 42%);
  --t-color-status-neutral: hsl(210, 18%, 87%);
  --t-color-status-success: hsl(128, 53%, 31%);
  --t-color-status-success-weakest: hsl(128, 60%, 40%, 20%);
  --t-color-status-warning: hsl(46, 88%, 53%);
  --t-color-status-warning-weak: hsl(46, 100%, 80%);
  --t-color-icon: hsl(0, 0%, 31%);
  --t-color-icon-inverted: hsl(0, 0%, 100%);
  --t-color-overlay: hsl(0, 0%, 55%, 50%);
  --t-color-highlight: hsl(25, 100%, 90%);
  --t-color-notice: hsl(243, 56%, 94%);
  --t-color-text-supporting: hsl(0, 0%, 40%);
  --t-color-status-warning-weakest: hsl(47, 100%, 93%);
  // override the --v-primary-base color (probably coming from mango)
  --v-primary-base: var(--t-color-primary) !important;
}
