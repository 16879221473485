/* In order to present tables correctly, we'd like nowrapping whitespace */
.table {

  th, td {
    vertical-align: middle;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.align-top > .table {
  th, td {
    vertical-align: top;
  }
}

th .sort {
  cursor: pointer;
}

.table .header {
  padding-right: 20px;
  font-weight: 300;

  &:hover {
    cursor: pointer;
  }
}

.headerSortUp,
.headerSortDown {
  white-space: nowrap;
  cursor: pointer;
  font-weight: 700 !important;
  color: theme-color("primary") !important;
  border-bottom-color: theme-color("primary") !important;

  &:after {
    display: inline-block;
    width: 0;
    height: 0;
    content: "";
    margin-right: -10px;
    margin-bottom: 2px;
    margin-left: 5px;
    border-right: 5px solid transparent;
    border-left:  5px solid transparent;
  }
}

.headerSortUp:after {
  border-top: 5px solid;
}

.headerSortDown:after {
  border-bottom: 5px solid;
}

.content-money {
  text-align: right;
  font-variant-numeric: tabular-nums;
}

.tci-sort,
[class^="tci-sort-"],
[class*=" tci-sort-"]
{
  color: #8E98A0;
  cursor: pointer;
}
